import Notiflix from "notiflix";
import { React, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { IoCallSharp, IoLocationSharp, IoSync } from "react-icons/io5";
import PostApiCall from "../../POSTAPI";
import ContactUsPageBanner from "../../assets/img/services-banner/contact-us-page-banner.png";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import PageBanner from "../../components/PageBanner/PageBanner";
import "./ContactUs.css";
import { Helmet } from "react-helmet";

function ContactUs() {
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    companyname: "",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
      .toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 90000000)
        .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  return (
    <>
      <Helmet>
        {/* <!-- Event snippet for Contact Form (2) conversion page --> */}
        {/* <script async>
    window.onload = function() {
        gtag('event', 'conversion', {'send_to': 'AW-11454203421/Wy0GCPmRr4cZEJ2M5dUq'})
    };
    </script> */}
        <script>
          {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'AW-11454203421');
                        gtag('event', 'conversion', {'send_to': 'AW-11454203421/Wy0GCPmRr4cZEJ2M5dUq'});
                    `}
        </script>
      </Helmet>
      <Navbar />
      <PageBanner bgImage={ContactUsPageBanner} />
      <section className="py-lg-5 py-4 contact-us-page">
        <Container fluid className="position-relative px-lg-5">
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="text-center mb-4 mb-lg-5">
              <p className="main-text text-center fst-italic wow animate__animated animate__fadeInUp">
                Take your Business to the Next Level
              </p>
              <p className="main-text text-center wow animate__animated animate__fadeInUp">
                Discover fulfilling roles at our dynamic company, unlocking your
                true potential. Submit your application, and we will respond
                promptly.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid className="px-lg-5">
          <Row>
            <Col lg={6} className="order-lg-1 order-1">
              <div className="mb-3">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14005.77027007341!2d77.1268733!3d28.6464644!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d031687ab8337%3A0x49673b54c7d958e6!2sGlobal%20Trendz!5e0!3m2!1sen!2sin!4v1701758545166!5m2!1sen!2sin?&maptype=terrain"
                  width="100%"
                  height="280"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  className="rounded"
                ></iframe>
              </div>
              <h3 className="fw-bold mb-3">Global Trendz</h3>
              <div className="mb-4">
                <h6 className="mb-lg-0 fw-bold">Delhi</h6>
                <div className="d-flex align-items-lg-center justify-content-start">
                  <div className="contact-us-icon">
                    <IoLocationSharp />
                  </div>
                  <div>
                    <a
                      href="https://maps.app.goo.gl/9VXyAzq5qvTTDE2n9"
                      target="_blank"
                    >
                      <p className="mb-0">
                        B 7/1, near Rajouri Garden, Upper Ground, <br /> Rajouri
                        Garden, New Delhi, Delhi - 110 027, INDIA.
                      </p>
                    </a>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-start">
                  <div className="contact-us-icon">
                    <IoCallSharp />
                  </div>
                  <div>
                    <a href="tel:+911147037722">+91 11 4703 7722</a>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-start">
                  <div className="contact-us-icon">
                    <IoCallSharp />
                  </div>
                  <div>
                    <a href="tel:+919821900322"> +91 98219 00322</a>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <h6 className="mb-lg-0 fw-bold">Chennai</h6>
                <div className="d-flex align-items-lg-center justify-content-start">
                  <div className="contact-us-icon">
                    <IoLocationSharp />
                  </div>
                  <div>
                    <p className="mb-0">
                      #621, 3rd Floor, Anna Salai, Chennai - 600 006. INDIA.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-start">
                  <div className="contact-us-icon">
                    <IoCallSharp />
                  </div>
                  <div>
                    <a href="tel:+914443099119">+91 44 4309 9119</a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="order-lg-1 order-1 mb-lg-0 mb-5">
              {/* <iframe src="https://globaltrendz.info/enquirygtmailer/1053/enquiry" class="contact-form-frame"></iframe> */}
              <h3 className="fw-bold mb-3">Let's Talk</h3>
              <p>
                Fill out the form and send us a message. We'd love to hear from
                you!
              </p>
              <Form>
                <Col lg={12}>
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Company Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Company Name"
                      name="companyname"
                      value={Enquiry.companyname}
                      onChange={(e) =>
                        setEnquiry({
                          ...Enquiry,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={12}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Name *"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={Enquiry.name}
                      onChange={(e) =>
                        setEnquiry({
                          ...Enquiry,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={12}>
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Mobile Number *"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Mobile Number"
                      name="mobile"
                      value={Enquiry.mobile}
                      onChange={(e) => {
                        if (e.target.value.length <= 10)
                          setEnquiry({
                            ...Enquiry,
                            [e.target.name]: e.target.value.replace(/\D/g, ""),
                          });
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={12}>
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Email *"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={Enquiry.email}
                      onChange={(e) =>
                        setEnquiry({
                          ...Enquiry,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={12}>
                  <FloatingLabel
                    controlId="floatingTextarea2"
                    label="Message *"
                    className="mb-3"
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: "100px" }}
                      name="message"
                      value={Enquiry.message}
                      onChange={(e) =>
                        setEnquiry({
                          ...Enquiry,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>

                <div className="col-12">
                  <div className="d-sm-flex d-block">
                    <p className="me-2 justify-content-between w-sm-50 w-100 d-sm-flex d-block captcha-bg">
                      {captcha_number}
                      <span
                        onClick={() => {
                          setRotatecaptcha("iorotate");
                          Genratecaptcha();
                        }}
                        className={
                          Rotatecaptcha == "iorotate" ? "iorotate" : ""
                        }
                      >
                        <IoSync className="aButton fs-3 rotate" />
                      </span>
                    </p>

                    <FloatingLabel
                      controlId="floatingPassword"
                      label="Captcha Code *"
                      className="mb-3 w-100"
                    >
                      <Form.Control
                        type="text"
                        className="form-control w-100"
                        name="captcha"
                        placeholder="Captcha Code *"
                        value={Enquiry.captcha}
                        onChange={(e) =>
                          setEnquiry({
                            ...Enquiry,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                  </div>
                </div>

                <Col className="d-lg-grid justify-content-end align-items-center">
                  <Button
                    className="btn btn-primary btn-lg talk"
                    onClick={() => {
                      if (Enquiry.name != "") {
                        // if (
                        //   Enquiry.email == "" ||
                        //   Enquiry.EmailRegex.test(Enquiry.email)
                        // )

                        if (Enquiry.mobile != "") {
                          if (Enquiry.mobile.length == 10) {
                            if (Enquiry.email != "") {
                              if (Enquiry.EmailRegex.test(Enquiry.email)) {
                                if (Enquiry.message != "") {
                                  if (
                                    Enquiry.captcha.toString() ==
                                    captcha_number.toString()
                                  ) {
                                    Notiflix.Loading.arrows("Please wait...");
                                    PostApiCall.postRequest(
                                      {
                                        name: Enquiry.name,
                                        mobile: Enquiry.mobile,
                                        email: Enquiry.email,
                                        message: Enquiry.message,
                                        companyname: Enquiry.companyname,
                                        clientid: 1066,
                                      },
                                      "Enqiury_Mail"
                                    ).then((results2) =>
                                      results2.json().then((obj2) => {
                                        if (
                                          results2.status == 200 ||
                                          results2.status == 201
                                        ) {
                                          setLoader(false);
                                          Notiflix.Notify.success(
                                            "Thank you, our team will contact you shortly!"
                                          );
                                          setTimeout(() => {
                                            window.location.reload();
                                          }, 2000);
                                        } else Notiflix.Loading.remove();
                                      })
                                    );
                                  } else {
                                    Notiflix.Notify.failure(
                                      "Please enter valid captcha"
                                    );
                                  }
                                } else {
                                  Notiflix.Notify.failure(
                                    "Please enter message"
                                  );
                                }
                              } else {
                                Notiflix.Notify.failure(
                                  "Please enter valid email"
                                );
                              }
                            } else {
                              Notiflix.Notify.failure("Please enter email");
                            }
                          } else {
                            Notiflix.Notify.failure(
                              "Please enter valid mobile number"
                            );
                          }
                        } else {
                          Notiflix.Notify.failure("Please enter mobile number");
                        }
                      }
                      //   else {
                      //     Notiflix.Notify.failure("Please enter valid email");
                      //   }
                      // }
                      else {
                        Notiflix.Notify.failure("Please enter name");
                      }
                    }}
                  >
                    {Loader == true ? (
                      <div class="d-flex justify-content-center">
                        <div
                          class="spinner-border"
                          role="status"
                          style={{ width: "1.4rem", height: "1.4rem" }}
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <span>Send Message </span>
                    )}
                  </Button>
                </Col>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default ContactUs;
