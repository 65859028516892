import React, { useState } from "react";
import { Col, Container, Row, Card, Button, Tab, Nav } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import ProductBechofy from "../../assets/img/product_logo_bechofy.png";
import ProductGrubDigest from "../../assets/img/product_logo_grubdigest.png";
import ProductKazamatrix from "../../assets/img/product_logo_kazamatrix.png";

function Products() {
  return (
    <section className="pt-lg-5 pt-5 bg-white global-products">
      <Container>
        <Row className="align-items-center pb-lg-5 pb-5 justify-content-center">
          <Col lg={10} className="text-center">
            <h2 className="main-title wow animate__animated animate__fadeInUp">
              Our Products
            </h2>
            <p className="main-text wow animate__animated animate__fadeInUp text-center">
              Our products cater to a wide range of industry verticals, helping
              you add the power of technology to enhance your business.
            </p>
            {/* <Link
              className="btn btn-outline-dark wow animate__animated animate__fadeInUp"
              to="/about"
            >
              Know More
            </Link> */}
          </Col>
        </Row>
      </Container>
      <Container fluid className="px-lg-5">
        <Row>
          <Col lg={4}>
            <a href="https://bechofy.in/" target="_blank">
              <div className="bg-bechofy order-lg-2 order-1">
                <img
                  src={ProductBechofy}
                  className="img-fluid d-block mx-auto wow animate__animated animate__zoomIn"
                  alt=""
                />
              </div>
            </a>
            <p className="mt-4 mb-2 fw-bold">
              Take Your Existing Business or Startup Online
            </p>
            <p className="prod-des mb-0">
              Selling online with Bechofy eCommerce enables you to achieve
              profitable results.
            </p>
            <p className="pt-lg-2 mb-5">
              <a
                href="https://bechofy.in/"
                className="website-link text-dark"
                target="_blank"
              >
                www.bechofy.in
              </a>
            </p>
          </Col>
          <Col lg={4}>
            <a href="http://www.grubdigest.com/" target="_blank">
              <div className="bg-grubdigest order-lg-1 order-1">
                <img
                  src={ProductGrubDigest}
                  className="img-fluid d-block mx-auto wow animate__animated animate__zoomIn"
                  alt=""
                />
              </div>
            </a>
            <p className="mt-4 mb-2 fw-bold">
              Bring Your Restaurant & Food Delivery Business Online
            </p>
            <p className="prod-des mb-0">
              Utilize the Grub Digest Online Food Ordering Platform to serve
              your customers directly through your own website.
            </p>
            <p className="pt-lg-2 mb-5">
              <a
                href="http://www.grubdigest.com/"
                className="website-link text-dark"
                target="_blank"
              >
                www.grubdigest.com
              </a>
            </p>
          </Col>
          <Col lg={4}>
            <a href="http://www.kazamatrix.com/" target="_blank">
              <div className="bg-kazamatrix order-lg-2 order-1">
                <img
                  src={ProductKazamatrix}
                  className="img-fluid d-block mx-auto wow animate__animated animate__zoomIn"
                  alt=""
                />
              </div>
            </a>
            <p className="mt-4 mb-2 fw-bold">
              Revolutionizing the Event Check-in Experience
            </p>
            <p className="prod-des mb-0">
              Experience a comprehensive event check-in solution tailored for
              trade show organizers, event managers, and more.
            </p>
            <p className="pt-lg-2 mb-5">
              <a
                href="http://www.kazamatrix.com/"
                className="website-link text-dark"
                target="_blank"
              >
                www.kazamatrix.com
              </a>
            </p>
          </Col>
        </Row>
        {/* <Row className="align-items-center">
          <Col lg={6} className="order-lg-1 order-2">
            <h2 className="section-highlighter wow animate__animated animate__fadeIn">
              Bechofy
            </h2>
            <h2 className="global-product-title wow animate__animated animate__fadeInUp">
              Excepteur sint occaecat cupidatat non proident!
            </h2>
            <p className="why-choose-text wow animate__animated animate__fadeInUp">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </Col>
          <Col lg={6} className="bg-bechofy order-lg-2 order-1">
            <img
              src={ProductBechofy}
              className="img-fluid d-block mx-auto wow animate__animated animate__zoomIn"
              alt=""
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={6} className="bg-grubdigest order-lg-1 order-1">
            <img
              src={ProductGrubDigest}
              className="img-fluid d-block mx-auto wow animate__animated animate__zoomIn"
              alt=""
            />
          </Col>
          <Col lg={6} className="order-lg-2 order-2 px-lg-5 px-2">
            <h2 className="section-highlighter wow animate__animated animate__fadeInUp">
              Grub Digest
            </h2>
            <h2 className="global-product-title wow animate__animated animate__fadeInUp">
              Excepteur sint occaecat cupidatat non proident.
            </h2>
            <p className="why-choose-text wow animate__animated animate__fadeInUp">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={6} className="order-lg-1 order-2 px-lg-5 px-2">
            <h2 className="section-highlighter wow animate__animated animate__fadeInUp">
              Kazamatrix
            </h2>
            <h2 className="global-product-title wow animate__animated animate__fadeInUp">
              Excepteur sint occaecat cupidatat non proident!
            </h2>
            <p className="why-choose-text wow animate__animated animate__fadeInUp">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </Col>
          <Col lg={6} className="bg-kazamatrix order-lg-2 order-1">
            <img
              src={ProductKazamatrix}
              className="img-fluid d-block mx-auto wow animate__animated animate__zoomIn"
              alt=""
            />
          </Col>
        </Row> */}
        {/* <Row className="align-items-center py-lg-5 py-5">
          <Col lg={6}>
            <p className="section-highlighter wow animate__animated animate__fadeInUp">
              Beauty Pie
            </p>
            <h2 className="why-choose-title wow animate__animated animate__fadeInUp">
              Excepteur sint occaecat cupidatat non proident!
            </h2>
            <p className="why-choose-text wow animate__animated animate__fadeInUp">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </Col>
          <Col lg={6}>
            <img
              src={{}}
              className="img-fluid d-block mx-auto wow animate__animated animate__zoomIn"
              alt=""
            />
          </Col>
        </Row>
        <Row className="align-items-center py-lg-5 py-5">
          <Col lg={6}>
            <p className="section-highlighter wow animate__animated animate__fadeInUp">
              Trade Faairs 247
            </p>
            <h2 className="why-choose-title wow animate__animated animate__fadeInUp">
              Excepteur sint occaecat cupidatat non proident!
            </h2>
            <p className="why-choose-text wow animate__animated animate__fadeInUp">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </Col>
          <Col lg={6}>
            <img
              src={{}}
              className="img-fluid d-block mx-auto wow animate__animated animate__zoomIn"
              alt=""
            />
          </Col>
        </Row>
        <Row className="align-items-center py-lg-5 py-5">
          <Col lg={6} className="order-lg-2 order-1">
            <p className="section-highlighter wow animate__animated animate__fadeInUp">
              The Web Pedia
            </p>
            <h2 className="why-choose-title wow animate__animated animate__fadeInUp">
              Excepteur sint occaecat cupidatat non proident!
            </h2>
            <p className="why-choose-text wow animate__animated animate__fadeInUp">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </Col>
          <Col lg={6} className="order-lg-1 order-2">
            <img
              src={{}}
              className="img-fluid d-block mx-auto wow animate__animated animate__zoomIn"
              alt=""
            />
          </Col>
        </Row> */}
      </Container>
    </section>
  );
}

export default Products;
