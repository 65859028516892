import { Col, Container, Row } from "react-bootstrap";
import WebsiteDesignDevelopment from "../../assets/img/landing-page/website/Website-Design.png"
import UIUXDesign from "../../assets/img/landing-page/website/UI-UX.png"
import WebApplicationDevelopment from "../../assets/img/landing-page/website/web-application-development.png"
import MobileApplicationDevelopment from "../../assets/img/landing-page/website/Mobile-app.png"
import SoftwareDevelopment from "../../assets/img/landing-page/website/Software-Development.png"
import ProgressiveWebApplicationDevelopment from "../../assets/img/landing-page/website/Progressive-MObile-app.png"
import Catalogues from "../../assets/img/landing-page/website/Ecatalog.png"
import EcommerceConsulting from "../../assets/img/landing-page/website/Ecommerce-consulting.png"
import EcommerceImplementation from "../../assets/img/landing-page/website/ecommerce-Implementation.png"
import ITConsulting from "../../assets/img/landing-page/website/it-consulting.png"
import ITOutsourcing from "../../assets/img/landing-page/website/it-outsource.png"
import DedicatedResource from "../../assets/img/landing-page/website/Dedicated-resource.png"



import "./Website.css"
import { Link } from "react-router";


export default function WebsiteServices() {
    return (
        <section className="information-technology-section digital-marketing-services py-lg-5 pb-4 pt-5 bg-white">
            <Container className="position-relative">
                <Row className="">
                    <Col lg={10} className="mx-auto">
                        <h2 className="main-title text-center main-title-3 wow animate__animated animate__fadeInUp mb-2">
                            A right solution can give you additional profits                       </h2>
                        {/* <p className="main-text text-center fst-italic wow animate__animated animate__fadeInUp">
                            Information Technology Solutions Streamlining your business with
                            the latest technology is the need of the hour.
                        </p> */}
                        <p className="main-text text-center wow animate__animated animate__fadeInUp mb-3 ">
                            Our Web and Software Solutions can help you redefine and improve the efficiency of your business. We Provide technical solutions that can help you showcase your business and also generate more sales.                        </p>
                    </Col>

                    <div className="col-lg-4">
                        <div className="technology-card text-center  ">
                            <div className="technology-card-inner">
                                <img
                                    src={WebsiteDesignDevelopment}
                                    className="img-fluid service-visual"
                                    alt=" Website Design & Development "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Website Design & Development                                </h3>
                                <p className="how-it-works-card__text">
                                    We offer a comprehensive range of web design services for both static and dynamic websites for various purposes such as personal, business, corporate, product catalogues, service listings, etc.                                </p>

                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={UIUXDesign}
                                    className="img-fluid service-visual"
                                    alt=" UI/UX Design  "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    UI/UX Design                              </h3>
                                <p className="how-it-works-card__text">
                                    Effective UI & UX design are integral components of any Website, Web App or Mobile App. These aspects define the ease of use and the overall effectiveness of the developed application.                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={WebApplicationDevelopment}
                                    className="img-fluid service-visual"
                                    alt="Web Application Development"
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Web Application Development                               </h3>
                                <p className="how-it-works-card__text">
                                    We understand your needs and provide you the right solutions in developing web-based utility applications and automating your business to enhance productivity & service.                       </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={MobileApplicationDevelopment}
                                    className="img-fluid service-visual"
                                    alt="     Mobile Application Development  "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Mobile Application Development                              </h3>
                                <p className="how-it-works-card__text">
                                    We specialise in developing Customised Hybrid & Native mobile applications for both iOS and Android for different Business Solutions & for Startup Concepts & Ideas.                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={SoftwareDevelopment}
                                    className="img-fluid service-visual"
                                    alt="Software Development"
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Software Development                                </h3>
                                <p className="how-it-works-card__text">
                                    If you are a Startup with an Idea or any Enterprise having a need for a technical solution, we understand your business needs and provide tailor-made software development solutions.                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={ProgressiveWebApplicationDevelopment}
                                    className="img-fluid service-visual"
                                    alt="   Progressive Web Application Development   "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Progressive Web Application Development                              </h3>
                                <p className="how-it-works-card__text">
                                    Our team of expert designers & developers turn your innovative ideas into reality by creating user-friendly and feature-rich Progressive Mobile Apps that cater to your specific business needs.                                </p>

                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={Catalogues}
                                    className="img-fluid service-visual"
                                    alt="    E-Catalogues & E-Brochures  "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    E-Catalogues & E-Brochures                               </h3>
                                <p className="how-it-works-card__text">
                                    Say Goodbye to traditional print brochures and embrace the future of interactive & attention-grabbing E-Brochures or E-Catalogues making it easy to showcase your products.                                </p>

                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={EcommerceConsulting}
                                    className="img-fluid service-visual"
                                    alt="       Ecommerce Consulting     "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Ecommerce Consulting                             </h3>
                                <p className="how-it-works-card__text">
                                    We analyse your current business model, target audience, competition and assist you in curating a compelling product listing and designing competitive campaigns.                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={EcommerceImplementation}
                                    className="img-fluid service-visual"
                                    alt="   Ecommerce Implementation"
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Ecommerce Implementation
                                </h3>
                                <p className="how-it-works-card__text">
                                    We provide a robust ecommerce platform and empower your business with a scalable and user-friendly online store that will captivate your customers and drive revenue growth.                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={ITConsulting}
                                    className="img-fluid service-visual"
                                    alt="                                    IT Consulting
"
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    IT Consulting
                                </h3>
                                <p className="how-it-works-card__text">
                                    We provide expert solutions to propel your business forward with innovative IT solutions and delivering tailored strategies that seamlessly align technology with your business goals.                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={ITOutsourcing}
                                    className="img-fluid service-visual"
                                    alt="                                    IT Outsourcing
"
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    IT Outsourcing
                                </h3>
                                <p className="how-it-works-card__text">
                                    We can build customised teams for your IT projects. If you have a specific requirement and a fixed timeline, utilising our team of experts can save your expense and time.                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={DedicatedResource}
                                    className="img-fluid service-visual"
                                    alt="Dedicated Resource"
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Dedicated Resource
                                </h3>
                                <p className="how-it-works-card__text">
                                    We offer dedicated resource services to help you find the right talent who become an integral part of your team and drive your project forward seamlessly and provide exceptional results.                                </p>
                            </div>
                        </div>

                    </div>
                </Row>
            </Container>



        </section >
    )

}