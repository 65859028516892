import { createContext, useState } from "react";
export const store = createContext();
const Provider = ({ children }) => {
  const [activeLink, setactiveLink] = useState("Home");

  return (
    <store.Provider
      value={{
        activeLink,
        setactiveLink,
      }}
    >
      {children}
    </store.Provider>
  );
};
export default Provider;
