import React, { useState } from "react";
import { Col, Container, Row, Card, Button, Tab, Nav } from "react-bootstrap";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { MdDoubleArrow } from "react-icons/md";
import { Link } from "react-router-dom";
import EcommerceImage from "../../assets/img/ecommerce.png";

function eCommerceServices() {
  return (
    <>
      <section className="bg-white ecommerce-service">
        <Container fluid className="position-relative px-lg-5">
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="text-center text-white">
              <p className="main-text text-center fst-italic">
                Selling your products and services online is a necessity today
                for most businesses.
              </p>
              <h2 className="main-title mb-4 mb-lg-5 text-center">
                Creating a successful eCommerce business model is all about
                having the right approach and a robust backend support system to
                manage the business. This is the key to having your venture to
                work.
              </h2>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col lg={5} className="text-white title-box position-relative">
              <span className="left-curve"></span>
              <div className="px-5 py-4 header-inner">
                <span className="text-white">
                  e-Commerce Consulting & Implementation
                </span>
              </div>
            </Col>
            <Col
              lg={7}
              className="px-5 pt-4 pb-3 py-md-3 title-box bg-white position-relative"
            >
              {/* <AiOutlineShoppingCart className="e-cart-icon" /> */}
              <div className="">
                <p className="fst-italic mb-02">
                  Competition Research: A Key Aspect
                </p>
                <h1 className="fw-bold">Consulting</h1>
                <p className="main-text">
                  Before venturing into eCommerce, you need to understand the
                  competition and the target market you should focus on. Our
                  business analyst team helps you in sorting out these aspects
                  and then collaborates with you to build your online presence,
                  providing the right solutions in both technology and
                  marketing.
                </p>
                <a
                  href={`/${"ecommerce consulting".replace(/ /g, "-")}`}
                  className="service-read-more"
                >
                  <MdDoubleArrow />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row className="justify-content-between">
            <Col lg={5} className="py-5 position-relative">
              {/* <img
                src={EcommerceImage}
                className="img-fluid w-50 mx-auto d-flex"
                alt="e-Commerce"
              /> */}
              <h3>One Platform Just for you</h3>
              <p>
                You can set up the store and manage your entire online business
                right from one platform.
              </p>{" "}
              <p>
                <strong>Bechofy - a gateway for online selling</strong>
              </p>
              <a
                href="tel:+917982828789"
                className="btn btn-primary talk mt-5px"
              >
                Start your Store
              </a>
            </Col>
            <Col lg={7} className="px-lg-5 pt-lg-3 py-2 position-relative">
              <div>
                <p className="fst-italic mb-0">Choosing the right Platform</p>
                <h1 className="fw-bold">Implementation</h1>
                <p>
                  Once the business model is set up, the next step is to
                  identify which platform to use based on your infrastructure
                  capabilities. We work with you to set up your eCommerce store
                  with the latest technology and the right marketing solutions
                  that can yield better returns.
                </p>
              </div>
              <a
                href={`/${"ecommerce implementation".replace(/ /g, "-")}`}
                className="service-read-more"
              >
                <MdDoubleArrow />
              </a>
            </Col>
          </Row>
          {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={5} className="py-5 position-relative">
                <span className="left-curve"></span>
                <Nav variant="pills" className="flex-column e-Commerce-tabs">
                  <Nav.Item className="e-Commerce-tab">
                    <Nav.Link eventKey="first" className="py-lg-4 ps-lg-5">
                      <div className="count">
                        <span>01</span>
                      </div>
                      <h5>e-Commerce Consulting</h5>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="e-Commerce-tab">
                    <Nav.Link eventKey="second" className="py-lg-4 ps-lg-5">
                      <div className="count">
                        <span>02</span>
                      </div>
                      <h5>e-Commerce Implementation</h5>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={7} className="p-lg-4">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <p className="fst-italic mb-0">
                      Competition Research a Key
                    </p>
                    <h1 className="fw-bold">e-Commerce Consulting</h1>
                    <p>
                      Before venturing into e-commerce you need to understand
                      competition and the target that you need to focus on. Our
                      business analyst team helps you in getting this sorted out
                      for you and then work with you in building your online
                      presence and give you the right solution.both in
                      technology and marketing.
                    </p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <p className="fst-italic mb-0">
                      Chosing the right Platform
                    </p>
                    <h1 className="fw-bold">e-Commerce Implementation</h1>
                    <p>
                      Once we have the business model setup the next step is to
                      identify which platform you need to use based on your
                      infrastructure capabilities. We work with you in setting
                      up your e-commerce store with the latest technology and
                      the right marketing solution which can get you more
                      returns.
                    </p>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container> */}
        </Container>
      </section>
    </>
  );
}

export default eCommerceServices;
