import { FaFacebookF, FaInstagram, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { VscFeedback } from "react-icons/vsc";

export default function FixedSMicons() {
  return (
    <div
      className="social-media-block"
      // style={{ bottom: visible ? "20px" : "50px" }}
      style={{ bottom: "20px" }}>
      <a href="https://www.facebook.com/globaltrendz" target="_blank">
        <FaFacebookF className="social-media-icon facebook" />
      </a>
      <a href="https://www.instagram.com/globaltrendz.india/" target="_blank">
        <FaInstagram className="social-media-icon" />
      </a>
      <a href="https://www.linkedin.com/company/globaltrendz" target="_blank">
        <FaLinkedinIn className="social-media-icon" />
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=+919821900322"
        target="_blank">
        <FaWhatsapp className="social-media-icon" />
      </a>
      {/* <a href="#" onClick={handleShow}> */}
      <a href="/contact-us">
        <VscFeedback className="social-media-icon" />
      </a>
    </div>
  );
}
