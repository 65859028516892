import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import SliderOne from "../../assets/img/main/sliderOne.png";
import SliderTwo from "../../assets/img/main/sliderTwo.png";
import SliderThree from "../../assets/img/main/sliderThree.png";
import SliderFour from "../../assets/img/main/sliderFour.png";
import "./Home.css";
import "animate.css";
import { FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { VscFeedback } from "react-icons/vsc";
import { Offcanvas } from "react-bootstrap";

function CarouselNew() {
  const [index, setIndex] = useState(0);
  const [showEnquiry, setShowEnquiry] = useState(false);
  const handleClose = () => setShowEnquiry(false);
  const handleShow = () => setShowEnquiry(true);

  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1000) {
      setVisible(true);
    } else if (scrolled <= 1000) {
      setVisible(false);
    }
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  window.addEventListener("scroll", toggleVisible);
  return (
    <div className="position-relative overflow-hidden">
      <Carousel
        fade
        variant="light"
        activeIndex={index}
        onSelect={handleSelect}
        className=""
      >
        <Carousel.Item className="vh-100" interval={6000}>
          <img
            className="d-block slider-img"
            src={SliderOne}
            alt="Global Trendz - Web | IT | Digital Marketing Services."
          />
          <Carousel.Caption className="">
            <h1>
              Understanding & <strong>Strategizing</strong> our clients'
              business and <strong>Delivering</strong> a robust IT solution is
              what we do.
              {/* 
              <strong>Understanding & Strategizing</strong> our clients business
              &amp; delivering a strong <strong>IT Solution</strong> is what we
              do. */}
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="vh-100" interval={6000}>
          <img
            className="d-block slider-img"
            src={SliderTwo}
            alt="Global Trendz - Web | IT | Digital Marketing Services."
          />
          <Carousel.Caption className="">
            <h1>
              e-Commerce <strong>Consulting</strong> &{" "}
              <strong>Implementation</strong> is the key to making your venture
              work.
              {/* <strong>e-Commerce Consulting &amp; Implementation</strong> is the{" "}
              <strong>Key</strong> to having your venture to work. */}
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="vh-100" interval={6000}>
          <img
            className="d-block slider-img"
            src={SliderThree}
            alt="Global Trendz - Web | IT | Digital Marketing Services."
          />
          <Carousel.Caption className="">
            <h1>
              Give your brand a <strong>Wider Reach</strong> of customers with
              the <strong>Influence</strong> of Digital Marketing.
              {/* Give your <strong>Brand</strong> a wider reach of customers with
              the power of <br />
              <strong>Digital Marketing</strong>. */}
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="vh-100" interval={6000}>
          <img
            className="d-block slider-img"
            src={SliderFour}
            alt="Global Trendz - Web | IT | Digital Marketing Services."
          />
          <Carousel.Caption className="">
            <h1>
              We <strong>Assist</strong> our customers in establishing an{" "}
              <strong>Impactful</strong> web presence for their business.
              {/* We help our <strong>Customers</strong> to build an effective{" "}
              <strong>Web Presence</strong> for their business. */}
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="right-bottom-curve"></div>
      <div className="right-top-curve"></div>
     
      <Offcanvas
        show={showEnquiry}
        onHide={handleClose}
        placement="end"
        id="enquiryForm"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
      {/* <div className="whatsapp-icon">
        <a
          href="https://api.whatsapp.com/send?phone=917982828789"
          target="_balnk"
        >
          <img src={whatsappChat} alt="whatsapp-chat" />
        </a>
      </div> */}
    </div>
  );
}

export default CarouselNew;
