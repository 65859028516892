import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CommonContactSection.css"
export default function CommonContactSection() {
  return (
    <>
      <Container fluid className="px-lg-5">
        <Row>
          <Col lg={12}>
            <div className="contact-us-inner d-lg-flex align-items-lg-center">
              <div className="contact-us-inner-text">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  Do You Have Any Requirements?<br />
                  Get In Touch With Us.
                </h2>
              </div>
              <div className="contact-us-inner-btn text-lg-center">
                <Link to="/contact-us" className="btn btn-primary talk mt-5px">
                  Contact Us
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
