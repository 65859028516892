import React, { useState } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import { FaCheck, FaChevronRight } from "react-icons/fa";
import AboutImgOne from "../../assets/img/featured-image-1.png";
import AboutImgTwo from "../../assets/img/featured-image-2.png";

function About() {
  return (
    <section
      className="about-section py-lg-space py-space bg-white"
      id="aboutUs"
    >
      <Container fluid className="position-relative px-lg-5">
        <Row className="align-items-start justify-content-center">
          <Col lg={6} className="image-column">
            <div className="inner">
              <div className="image-block">
                <img
                  src={AboutImgOne}
                  alt="IT Services and Brand Managment company"
                />
              </div>
              <div className="image-block">
                <img
                  src={AboutImgTwo}
                  className="image-2"
                  alt="IT Services and Brand Managment company"
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="text-column px-lg-5 py-1">
            <div className="inner">
              {/* <h2 className="main-title wow animate__animated animate__fadeInUp">
                Who we are
              </h2> */}
              <h2 className="main-title mb-4 wow animate__animated animate__fadeInUp">
                We are a Consulting, IT Services, and Brand Management Company
                based in INDIA.
              </h2>
              <p className="main-text wow animate__animated animate__fadeInUp">
                We deliver Business Techno Consulting, Internet-Based Software
                Solutions, Mobile Application Solutions, and Branding services
                to clients in India and around the world.
              </p>
              <p className="main-text wow animate__animated animate__fadeInUp">
                Providing services for over 25 years, we have clients spanning
                various industries such as Food & Hospitality, Manufacturing
                Sectors, Trading & Marketing Services, Logistics, Educational
                Institutions, Charity Organizations, Human Resources, Event
                Management, Hospitals, Pharmaceuticals, Exporters & Importers,
                Individual Professionals, etc.
              </p>
              <div className="text wow animate__animated animate__fadeInUp">
                <div className=" d-flex">
                  {/* <ul className="list-unstyled m-0 about-list">
                    <li className="d-flex justify-content-start align-items-center gap-2">
                      <FaCheck className="list-check" />
                      Suspe ndisse suscipit sagittis leo.
                    </li>
                    <li className="d-flex justify-content-start align-items-center gap-2">
                      <FaCheck className="list-check" />
                      Entum estibulum dignissim posuere.
                    </li>
                    <li className="d-flex justify-content-start align-items-center gap-2">
                      <FaCheck className="list-check" />
                      If you are going to use a passage.
                    </li>
                  </ul> */}
                  <p className="main-text mb-0 since-p">
                    Our forte lies in our capability to understand a client's
                    requirement and translate it into profitable solutions. Our
                    solutions are not only effective but also economical without
                    compromising on quality and efficient production.
                  </p>
                  <div className="since">
                    <span className="txt">
                      Since <br />
                      1996
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default About;
