import { useEffect, useState } from "react";
import "./Blog.css";
import { Link } from "react-router-dom";

import reasons from "../../assets/img/Blog-section/5-Reasons-Why-You-Need-a-Digital-Marketing-Company.jpg"
import challenges from "../../assets/img/Blog-section/Challenges-in-Digital-Marketing-and-How-to-Overcome-Them.jpg"
import future from "../../assets/img/Blog-section/The-Future-of-E-commerce-and-Digital-Marketing-Navigating-the-Next-Decade.jpg"
import importance from "../../assets/img/Blog-section/The-Importance-of-User-Generated-Content-in-Digital-Marketing.jpg"
import build from "../../assets/img/Blog-section/how-to-build-a-strong-online-brand-presence.jpg";
import emailmarketing from "../../assets/img/Blog-section/email-marketing.jpg"
import influencersmall from "../../assets/img/Blog-section/influencer-small.png"
import ai from "../../assets/img/Blog-page/The-Role-of-AI-and-Automation-in-Digital-Marketing-right.jpg"
export default function RecentBlogSection() {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        if (window.pageYOffset > 0) {
            setScroll("scroll");
        } else {
            window.addEventListener("scroll", () => {
                setScroll(window.scrollY > 200);
            });
        }
    }, []);
    return (
        <div className={
            scroll ? "active sticky-top recent-blogs" : "sticky-top recent-blogs"}>
            <h5 class="fw-bold">Recent Blogs</h5>



            <div className="blog-btn-card mt-lg-0 mt-4 px-0">
                <ul className="mb-0">
                    <li className="d-flex mb-3 blog-side-card">
                        <div className="blog-side-image">
                            <a href="/the-role-of-ai-and-automation-in-digital-marketing">
                                <img
                                    src={ai}
                                    className="img-fluid"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="blog-side-content">
                            <div>
                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>

                                <span className="my-3">16-11-2024</span>
                            </div>

                            <a className="" href="/the-role-of-ai-and-automation-in-digital-marketing">                                The Role of AI and Automation in Digital Marketing


                            </a>
                        </div>
                    </li>
                    <li className="d-flex mb-3 blog-side-card">
                        <div className="blog-side-image">
                            <a href="/best-strategies-for-successful-email-marketing-campaign">
                                <img
                                    src={emailmarketing}
                                    className="img-fluid"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="blog-side-content">
                            <div>
                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>

                                <span className="my-3">09-11-2024</span>
                            </div>

                            <a className="" href="/best-strategies-for-successful-email-marketing-campaign">                                Best Strategies for Successful Email Marketing Campaign

                            </a>
                        </div>
                    </li>
                    <li className="d-flex mb-3 blog-side-card">
                        <div className="blog-side-image">
                            <a href="/influencer-marketing-how-to-choose-the-right-partners">
                                <img
                                    src={influencersmall}
                                    className="img-fluid"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="blog-side-content">
                            <div>
                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>

                                <span className="my-3">26-10-2024</span>
                            </div>

                            <a className="" href="/influencer-marketing-how-to-choose-the-right-partners">Influencer Marketing: How to Choose the Right Partners
                            </a>
                        </div>
                    </li>
                    <li className="d-flex mb-3 blog-side-card">
                        <div className="blog-side-image">
                            <a href="/5-reasons-why-you-need-a-digital-marketing-company">
                                <img
                                    src={reasons}
                                    className="img-fluid"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="blog-side-content">
                            <div>
                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>

                                <span className="my-3">24-09-2024</span>
                            </div>

                            <a className="" href="/5-reasons-why-you-need-a-digital-marketing-company">5 Reasons Why You Need a Digital Marketing Company</a>
                        </div>
                    </li>
                    {/* <li className="d-flex mb-3 blog-side-card">
                        <div className="blog-side-image">
                            <a href="/challenges-in-digital-marketing-and-how-to-overcome-them">
                                <img
                                    src={challenges}
                                    className="img-fluid"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="blog-side-content">
                            <div>
                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>

                                <span className="my-3">24-09-2024</span>
                            </div>

                            <a className="" href="/challenges-in-digital-marketing-and-how-to-overcome-them">Challenges in Digital Marketing and How to Overcome Them
                            </a>
                        </div>
                    </li> */}
                    {/* <li className="d-flex mb-3 blog-side-card">
                        <div className="blog-side-image">
                            <a href="/the-future-of-e-commerce-and-digital-marketing:navigating-the-next-decade">
                                <img
                                    src={future}
                                    className="img-fluid"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="blog-side-content">
                            <div>
                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>

                                <span className="my-3 ">24-09-2024</span>
                            </div>

                            <a className="" href="/the-future-of-e-commerce-and-digital-marketing:navigating-the-next-decade">The Future of E-commerce and Digital Marketing: Navigating the Next Decade
                            </a>
                        </div>
                    </li> */}
                    {/* <li className="d-flex p-0 blog-side-card">
                        <div className="blog-side-image">
                            <a href="/how-to-build-a-strong-online-brand-presence">
                                <img
                                    src={build}
                                    className="img-fluid"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="blog-side-content">
                            <div>
                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>

                                <span className="my-3">24-09-2024</span>
                            </div>

                            <a className="" href="/how-to-build-a-strong-online-brand-presence">How to Build a Strong Online Brand Presence
                            </a>
                        </div>
                    </li> */}
                    {/* <li className="d-flex mb-3 blog-side-card border-0 pb-0">
                        <div className="blog-side-image">
                            <a href="/the-importance-of-user-generated-content-in-digital-marketing">
                                <img
                                    src={importance}
                                    className="img-fluid"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="blog-side-content">
                            <div>
                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>

                                <span className="my-3">24-09-2024</span>
                            </div>

                            <a className="" href="/the-importance-of-user-generated-content-in-digital-marketing">The Importance of User-Generated Content in Digital Marketing</a>
                        </div>

                    </li> */}
                </ul>

                <div className="read-more-button d-flex align-items-center justify-content-center">
                    <a href="/blogs" class="btn btn-primary talk mt-5px">Read More</a>

                </div>

            </div>



        </div>
    )
}