import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Col, Container, Row, Card, Button, Accordion } from "react-bootstrap";
import "./Footer.css";
import FooterLogo from "../../assets/img/logo.png";
import { FiChevronUp } from "react-icons/fi";
import { FaChevronRight } from "react-icons/fa";
import {
  AiOutlineEnvironment,
  AiOutlineInfoCircle,
  AiOutlineMail,
  AiOutlineMobile,
} from "react-icons/ai";
import { RiTeamLine } from "react-icons/ri";
import { BiSupport } from "react-icons/bi";
import { HiOutlineChartBar } from "react-icons/hi";

function Footer() {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1000) {
      setVisible(true);
    } else if (scrolled <= 1000) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <>
      <footer className="bg-dark text-white footer">
        <Container fluid className="px-lg-5">
          <Row className="py-lg-5 justify-content-center align-content-center">
            <Col lg={12} className="text-center mb-4 mb-lg-5 fw-bold">
              <h1>Elevate Your Business to the Next Level</h1>
              <p>
                We are a global company offering consulting, IT services,
                eCommerce solutions, web design, mobile app development, and
                digital marketing solutions.
              </p>
            </Col>
            <Col
              lg={3}
              xs={6}
              className="mb-lg-0 mb-2 d-flex justify-content-lg-start justify-content-center gap-2 flex-lg-row flex-column text-center text-lg-end"
            >
              <div className="global-strength-icon">
                <AiOutlineInfoCircle />
              </div>
              <div>
                <p className="global-strength-title">Enquiry</p>
                <p>
                  {" "}
                  <a href="tel:+919821900322" name="Call for Enquiry">
                    {" "}
                    {/* +91 79828 28789 */}
                    +91 98219 00322
                  </a>
                </p>
                <p>
                  <a
                    href="mailto:info@globaltrendz.com"
                    name="Email for Enquiry"
                  >
                    {" "}
                    info@globaltrendz.com
                  </a>
                </p>
              </div>
            </Col>
            <Col
              lg={3}
              xs={6}
              className="mb-lg-0 mb-2 d-flex justify-content-lg-start justify-content-center gap-2 flex-lg-row flex-column text-center text-lg-end"
            >
              <div className="global-strength-icon">
                <HiOutlineChartBar />
              </div>
              <div>
                <p className="global-strength-title">Sales</p>
                <p>
                  <a href="tel:+919821900322">+91 98219 00322</a>
                </p>
                <p>
                  <a href="mailto:sales@globaltrendz.com">
                    {" "}
                    sales@globaltrendz.com
                  </a>
                </p>
              </div>
              {/* <div>
                <div className="footer-widget logo-widget">
                  <div className="widget-content">
                    <div>
                      <a
                        className="logo-box mb-2"
                        href="http://layerdrops.com/linoorwp/"
                      >
                        <img src={FooterLogo} className="logo" alt="" />
                      </a>
                    </div>
                    <div className="text pe-lg-5">
                      Welcome to our web design agency. Lorem ipsum simply free
                      text dolor sited amet cons cing elit. Web
                      design agency. Lorem ipsum simply free text dolor sited
                      amet cons cing elit. <br/>
                      Lorem ipsum simply free text dolor sited amet cons sing
                      elit.
                    </div>
                  </div>
                </div>
              </div> */}
            </Col>
            <Col
              lg={3}
              xs={6}
              className="mb-lg-0 mb-3 d-flex justify-content-lg-start justify-content-center gap-2 flex-lg-row flex-column text-center text-lg-end"
            >
              <div className="global-strength-icon">
                <BiSupport />
              </div>
              <div>
                <p className="global-strength-title">Support</p>
                <p>
                  <a href="tel: +91 79828 28789">  +91 79828 28789</a>
                </p>
                <p>
                  <a href="mailto:support@globaltrendz.com">
                    support@globaltrendz.com
                  </a>
                </p>
              </div>
              {/* <h5>Explore</h5>
              <ul className="mega-menu-list">
                <li>
                  <Link to="/">
                    <FaChevronRight /> Software Development
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaChevronRight /> IT Consultancy
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaChevronRight /> Oracle Consulting
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaChevronRight /> IT Outsource
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaChevronRight /> Dedicated Resource
                  </Link>
                </li>
              </ul> */}
            </Col>
            <Col
              lg={3}
              xs={6}
              className="mb-lg-0 mb-3 d-flex justify-content-lg-start justify-content-center gap-2 flex-lg-row flex-column text-center text-lg-end"
            >
              <div className="global-strength-icon">
                <RiTeamLine />
              </div>
              <div>
                <p className="global-strength-title">Join Our Team</p>
                <p>
                  <a href="tel:+917603999622">+91 76039 99622</a>
                </p>
                <p>
                  <a href="mailto:hr@globaltrendz.com">hr@globaltrendz.com</a>
                </p>
              </div>
              {/* <h5>Contact</h5>
              <ul className="contact-info m-0 list-unstyled">
                <li className="address">
                  <AiOutlineEnvironment />
                  <p>
                    #621, 3rd Floor, Anna Salai,
                    <br />
                    Chennai - 600 006. INDIA. <br />
                    Phone: +91 44 4309 9119
                  </p>
                </li>
                <li className="address">
                  <AiOutlineEnvironment />
                  <p>
                    D-200, 3rd Floor, Mansarover Garden,
                    <br />
                    New Delhi - 110 015. INDIA.
                    <br />
                    Phone: +91 11 4703 7722
                  </p>
                </li>
                <li className="address">
                  <AiOutlineMobile />
                  <a href="tel:+917982828789">+91-79828 28789</a>
                </li>
                <li className="address">
                  <AiOutlineMail />
                  <a href="mailto:info@globaltrendz.com">
                    info@globaltrendz.com
                  </a>
                </li>
              </ul> */}
            </Col>
          </Row>
          <Row className="border-top mt-2 mt-lg-0 mx-0 pt-lg-4 pb-lg-3 py-3 justify-content-center align-content-center">
            {/* <div className="design col-lg-6 px-0 order-lg-1 order-2">
              Design{" "}
              <a href="http://globaltrendz.com/" target="_blank">
                Global Trendz
              </a>
            </div> */}
            <div className="copyright col-lg-12 px-0">
              &copy; {moment().format("YYYY")} Global Trendz. All Rights
              Reserved.
            </div>
          </Row>
        </Container>
      </footer>
      <a
        className="back-to-top"
        onClick={scrollToTop}
        style={{ opacity: visible ? "1" : "0" }}
      >
        <FiChevronUp />
      </a>
    </>
  );
}

export default Footer;
