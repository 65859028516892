import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import SliderOne from "../../assets/img/landing-page/digital/home-banner.png";
import SliderOneMobile from "../../assets/img/landing-page/digital/mobile-banner.png";
import SliderTwo from "../../assets/img/main/sliderTwo.png";
import SliderThree from "../../assets/img/main/sliderThree.png";
import SliderFour from "../../assets/img/main/sliderFour.png";
import "animate.css";
import Notiflix from "notiflix";
import { Button, Col, Container, Row } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { IoSync } from "react-icons/io5";
import PostApiCall from "../../POSTAPI";
import "./DigitalMarketing.css"
import DigitalForm from "./Form";


function DigitalCarousel() {
    const [index, setIndex] = useState(0);
    const [showEnquiry, setShowEnquiry] = useState(false);
    const handleClose = () => setShowEnquiry(false);
    const handleShow = () => setShowEnquiry(true);

    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 1000) {
            setVisible(true);
        } else if (scrolled <= 1000) {
            setVisible(false);
        }
    };

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    window.addEventListener("scroll", toggleVisible);
    return (
        <div className="position-relative overflow-hidden">
            <Carousel
                indicators={false}
                fade
                variant="light"
                activeIndex={index}
                onSelect={handleSelect}
                className="digital-marketing-slider"
            >
                <Carousel.Item className="vh-100" interval={6000}>
                    <img
                        className="d-none d-md-block slider-img"
                        src={SliderOne}
                        alt="Global Trendz - Web | IT | Digital Marketing Services."
                    />
                    <img
                        className="d-block d-md-none slider-img"
                        src={SliderOneMobile}
                        alt="Global Trendz - Web | IT | Digital Marketing Services."
                    />
                    <Carousel.Caption className="">
                        <h1>
                            <strong>Take your business to the world</strong> <br />

                            <span className="fs-4">We help in fueling your business with Innovation, Creativity, Marketing, Brand Building, Technology & Support</span>
                        </h1>
                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>
            <div className="right-bottom-curve"></div>
            <div className="right-top-curve"></div>

            <div className="form-digital-marketing d-none d-lg-block">
                <DigitalForm />
            </div >

        </div >
    );
}

export default DigitalCarousel;
