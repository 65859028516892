import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import ContactUsPageBanner from "../../assets/img/services-banner/contact-us-page-banner.png";
import PageBanner from "../../components/PageBanner/PageBanner";
function ReturnPolicy() {
    return (
        <>
            <Navbar />
            <PageBanner bgImage={ContactUsPageBanner} />
            <section className="pt-5 contact-us-page">
                <Container fluid className="position-relative px-lg-5">
                    <Row className="align-items-center justify-content-center">
                        <Col lg={12} className="mb-4 mb-lg-5">
                            <h2 className="main-title main-title-3 wow animate__ animate__fadeInUp animated">Refund Policy for Global Trendz - Software Development Services</h2>
                            <p>Last Updated: 1st January 2019</p>
                            <p class="our">
                                Thank you for choosing Global Trendz for your software development
                                needs. We strive to provide high-quality services, and your satisfaction
                                is our priority. Please review our refund policy outlined below.
                            </p>
                            <p class="such"><strong>Effective Date:</strong> 1st January 2019</p>

                            <h3>1. Refund Eligibility</h3>

                            <p class="our">
                                1.1 <strong>Initial Consultation Fee:</strong> Any fees charged for the
                                initial consultation or project assessment are non-refundable.
                            </p>
                            <p class="our">
                                1.2 <strong>Advance Payments:</strong> If an advance payment or deposit
                                is required to initiate a project, it is non-refundable once work has
                                commenced.
                            </p>
                            <p class="our">
                                1.3 <strong>Milestone Payments:</strong> Payments made for specific
                                project milestones are non-refundable once the associated work has been
                                completed.
                            </p>

                            <h3>2. Service Cancellation</h3>

                            <p class="our">
                                2.1 <strong>Client-Requested Cancellation:</strong> If a client wishes
                                to cancel a project after it has been initiated, any fees paid up to
                                that point will not be refunded.
                            </p>
                            <p class="our">
                                2.2 <strong>Company-Requested Cancellation:</strong> In rare cases where
                                Global Trendz is unable to fulfill its obligations, a refund may be
                                issued for any uncompleted work or services.
                            </p>

                            <h3>3. Quality Assurance</h3>

                            <p class="our">
                                3.1 <strong>Bug Fixes:</strong> We are committed to delivering
                                high-quality software. If any bugs or issues arise within a reasonable
                                time after the completion of the project, we will work to address them
                                at no additional cost.
                            </p>
                            <p class="our">
                                3.2 <strong>Refunds for Defective Work:</strong> In the event that the
                                delivered software does not meet the agreed-upon specifications and
                                cannot be remedied, a refund may be considered on a case-by-case basis.
                            </p>

                            <h3>4. Refund Requests</h3>

                            <p class="our">
                                4.1 <strong>Submission of Requests:</strong> Refund requests must be
                                submitted in writing to <a href="mailto:info@globaltrendz.com"
                                >info@globaltrendz.com</a> within 10 days of the project's completion or the occurrence of any
                                issue.
                            </p>
                            <p class="our">
                                4.2 <strong>Providing Details:</strong> When requesting a refund, please
                                provide your project details, a description of the issue, and any
                                relevant supporting documentation.
                            </p>

                            <h3>5. Changes to This Refund Policy</h3>

                            <p class="our">
                                We reserve the right to update or modify this refund policy at any time.
                                Any changes will be effective immediately upon posting the updated
                                policy on our website.
                            </p>

                            <h3>6. Contact Us</h3>

                            <p class="our">
                                If you have any questions or concerns about our refund policy, please
                                contact us at <a href="mailto:info@globaltrendz.com"
                                >info@globaltrendz.com</a>.
                            </p>

                            <p class="our">
                                By engaging in a project with Global Trendz, you agree to the terms
                                outlined in this refund policy.
                            </p>
                            <div id="fb-root"></div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default ReturnPolicy;
