import { Col, Container, Row } from "react-bootstrap";
import DedicatedResources from "../../assets/img/home-it/Dedicated-Resources.png";
import ItConsultancyServices from "../../assets/img/home-it/IT-Consultancy-Services.png";
import ItOutsource from "../../assets/img/home-it/IT-Outsource.png";
import Oracleconsulting from "../../assets/img/home-it/Oracle-consulting.png";
import SoftwareDevelopement from "../../assets/img/home-it/Software-Developement.png";
import BrandAudit from "../../assets/img/landing-page/digital/Brand-Audit.png";
import BrandManagement from "../../assets/img/landing-page/digital/Brand-Management.png";
import SocialMediaMarketing from "../../assets/img/landing-page/digital/Social-Media-Marketing.png";
import SearchEngineOptimization from "../../assets/img/landing-page/digital/SEO.png";
import LinkedInMarketing from "../../assets/img/landing-page/digital/Linked-in.png";
import YouTubeMarketing from "../../assets/img/landing-page/digital/Youtube.png";
import Advertisement from "../../assets/img/landing-page/digital/Digital-Advertising.png";
import InfluencerMarketing from "../../assets/img/landing-page/digital/Influencer.png";
import PhotographyVideography from "../../assets/img/landing-page/digital/Photography.png";

import "./DigitalMarketing.css"
import { Link } from "react-router";

export default function DigitalServices() {
    return (
        <section className="information-technology-section digital-marketing-services py-lg-5 pb-4 pt-5 bg-white">
            <Container className="position-relative">
                <Row className="">
                    <Col lg={10} className="mx-auto">
                        <h2 className="main-title text-center main-title-3 wow animate__animated animate__fadeInUp mb-2">
                            A good marketing initiative can get you more customers!                        </h2>
                        {/* <p className="main-text text-center fst-italic wow animate__animated animate__fadeInUp">
                            Information Technology Solutions Streamlining your business with
                            the latest technology is the need of the hour.
                        </p> */}
                        <p className="main-text text-center wow animate__animated animate__fadeInUp mb-3 ">
                            We can provide Innovating, Engaging & Insight Driven Strategies to enhance your Digital Marketing Transformation for your Business. Our team of dedicated digital marketers work closely with you to give your business the needed boost in generating more sales.
                        </p>
                    </Col>

                    <div className="col-lg-4">
                        <div className="technology-card text-center  ">
                            <div className="technology-card-inner">
                                <img
                                    src={BrandAudit}
                                    className="img-fluid service-visual"
                                    alt="                                    Brand Audit
"
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Brand Audit
                                </h3>
                                <p className="how-it-works-card__text">
                                    We master the art of Understanding & Analyzing the clients business before making an effective marketing plan which is the key to a successful brand promotion strategy.
                                </p>

                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={BrandManagement}
                                    className="img-fluid service-visual"
                                    alt="     Brand Management "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Brand Management                                </h3>
                                <p className="how-it-works-card__text">
                                    Building a strong brand presence is very important for any business. We enable clients to position themselves above competitors by creating innovative branding collaterals.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={SocialMediaMarketing}
                                    className="img-fluid service-visual"
                                    alt="  Social Media Marketing    "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Social Media Marketing                                </h3>
                                <p className="how-it-works-card__text">
                                    Staying updated on social media platforms with engaging strategic content can be the most effective way to reach out to customers. We give you a 360o Social Media Solution.                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={SearchEngineOptimization}
                                    className="img-fluid service-visual"
                                    alt="   Search Engine Optimization "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Search Engine Optimization                                </h3>
                                <p className="how-it-works-card__text">
                                    Getting found on Search Engines is the most cost effective way to generate organic traffic for your business. We solve this by using the best practices of SEO to target audiences.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={LinkedInMarketing}
                                    className="img-fluid service-visual"
                                    alt="  LinkedIn Marketing"
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    LinkedIn Marketing
                                </h3>
                                <p className="how-it-works-card__text">
                                    Linkedin Marketing is one of the most effective platforms for B2B customers who want their products and services targeted to the right audience and get connected with them.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={YouTubeMarketing}
                                    className="img-fluid service-visual"
                                    alt=" YouTube Marketing "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    YouTube Marketing                                </h3>
                                <p className="how-it-works-card__text">
                                    Video based marketing on youtube is very effective in showcasing your business, products and services to customers as you can be very descriptive, engaging & eye-catching.
                                </p>

                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={Advertisement}
                                    className="img-fluid service-visual"
                                    alt="    Advertisement  "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Advertisement                                </h3>
                                <p className="how-it-works-card__text">
                                    Advertising your business on Digital Media platforms is a very quick, easy & effective method to reach out to targeted multitude of potential customers in a short period of time.
                                </p>

                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={InfluencerMarketing}
                                    className="img-fluid service-visual"
                                    alt="  Influencer Marketing   "
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Influencer Marketing                                </h3>
                                <p className="how-it-works-card__text">
                                    Influencers give you an instant boost to your business on social media platforms. We help in coordinating, sourcing and content creation by collaborating with resourceful  influencers.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="technology-card text-center">
                            <div className="technology-card-inner">
                                <img
                                    src={PhotographyVideography}
                                    className="img-fluid service-visual"
                                    alt="    Photography & Videography"
                                />
                                {/* <p className="fst-italic mb-0">Subheading</p> */}
                                <h3 className="how-it-works-card__title">
                                    Photography & Videography
                                </h3>
                                <p className="how-it-works-card__text">
                                    Photographs & Videos are very important for any business to generate creative content for promotion. Our inhouse team of professionals enables you to achieve that at ease.
                                </p>
                            </div>
                        </div>

                    </div>
                </Row>
            </Container>



        </section >
    )

}