import { Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home/Home";
import Provider from "./components/Context/store";
import Technologies from "./pages/Technologies/Technologies";
import ContactUs from "./pages/ContactUs/ContactUs";
import Careers from "./pages/Careers/Careers";
import SoftwareDevelopment from "./pages/Services/SoftwareDevelopment/SoftwareDevelopment";
import ItConsulting from "./pages/Services/ItConsulting/ItConsulting";
import OracleConsulting from "./pages/Services/OracleConsulting/OracleConsulting";
import ItOutsource from "./pages/Services/ItOutsource/ItOutsource";
import DedicatedResource from "./pages/Services/DedicatedResource/DedicatedResource";
import EcommerceConsulting from "./pages/Services/EcommerceConsulting/EcommerceConsulting";
import WebApplicationDevelopment from "./pages/Services/WebApplicationDevelopment/WebApplicationDevelopment";
import WebsiteDesignAndDevelopment from "./pages/Services/WebsiteDesignAndDevelopment/WebsiteDesignAndDevelopment";
import EcatalogsAndEbrochures from "./pages/Services/EcatalogsAndEbrochures/EcatalogsAndEbrochures";
import MobileApplicationDevelopment from "./pages/Services/MobileApplicationDevelopment/MobileApplicationDevelopment";
import ProgressiveWebApplications from "./pages/Services/ProgressiveWebApplications/ProgressiveWebApplications";
import UiAndUxDesign from "./pages/Services/UiAndUxDesign/UiAndUxDesign";
import SEO from "./pages/Services/SEO/SEO";
import SEM from "./pages/Services/SEM/SEM";
import SMM from "./pages/Services/SMM/SMM";
import DigitalMediaAdvertising from "./pages/Services/DigitalMediaAdvertising/DigitalMediaAdvertising";
import LeadGeneration from "./pages/Services/LeadGeneration/LeadGeneration";
import ContenWriting from "./pages/Services/ContenWriting/ContenWriting";
import GraphicDesigning from "./pages/Services/GraphicDesigning/GraphicDesigning";
import EcommerceImpimplementation from "./pages/Services/EcommerceImpimplementation/EcommerceImpimplementation";
import PrivacyPolicy from "./pages/About/PrivacyPolicy";
import TermsOfUse from "./pages/About/TermsOfUse";
import Disclaimer from "./pages/About/Disclaimer";
import RefundPolicy from "./pages/About/RefundPolicy";
import FixedSMicons from "./components/FixedSMicons/FixedSMicons";
import DigitalMarketing from "./pages/DigitalMarketing/DigitalMarketing";
import Website from "./pages/Website/Website";
import WhatsApp from "./pages/WhatsApp/WhatsApp";
import BlogOne from "./pages/Blog/BlogOne";
import BlogTwo from "./pages/Blog/BlogTwo";
import BlogThree from "./pages/Blog/BlogThree";
import BlogFour from "./pages/Blog/BlogFour";
import BlogFive from "./pages/Blog/BlogFive";
import BlogSix from "./pages/Blog/BlogSix";

import BlogPage from "./pages/BlogPage/BlogPage";
import BlogSeven from "./pages/Blog/BlogSeven";
import BlogEight from "./pages/Blog/BlogEight";
import BlogNine from "./pages/Blog/BlogNine";

function App() {
  return (
    <Provider>
      <FixedSMicons />
      <Routes>

        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/whatsapp" element={<WhatsApp />}></Route>
        <Route exact path="/technologies" element={<Technologies />}></Route>
        <Route exact path="/work-with-us" element={<Careers />}></Route>
        <Route exact path="/contact-us" element={<ContactUs />}></Route>
        <Route
          exact
          path="/software-development"
          element={<SoftwareDevelopment />}
        ></Route>
        <Route exact path="/it-consulting" element={<ItConsulting />}></Route>
        <Route
          exact
          path="/oracle-consulting"
          element={<OracleConsulting />}
        ></Route>
        <Route exact path="/it-outsource" element={<ItOutsource />}></Route>
        <Route
          exact
          path="/dedicated-resource"
          element={<DedicatedResource />}
        ></Route>
        <Route
          exact
          path="/ecommerce-consulting"
          element={<EcommerceConsulting />}
        ></Route>
        <Route
          exact
          path="/ecommerce-implementation"
          element={<EcommerceImpimplementation />}
        ></Route>
        <Route
          exact
          path="/website-design-and-development"
          element={<WebsiteDesignAndDevelopment />}
        ></Route>
        <Route
          exact
          path="/ecatalogues-and-ebrochures"
          element={<EcatalogsAndEbrochures />}
        ></Route>
        <Route
          exact
          path="/web-application-development"
          element={<WebApplicationDevelopment />}
        ></Route>
        <Route
          exact
          path="/mobile-application-development"
          element={<MobileApplicationDevelopment />}
        ></Route>
        <Route
          exact
          path="/progressive-web-applications"
          element={<ProgressiveWebApplications />}
        ></Route>
        <Route
          exact
          path="/ui-and-ux-design"
          element={<UiAndUxDesign />}
        ></Route>
        <Route
          exact
          path="/search-engine-optimization"
          element={<SEO />}
        ></Route>
        <Route exact path="/search-engine-marketing" element={<SEM />}></Route>
        <Route exact path="/social-media-marketing" element={<SMM />}></Route>
        <Route
          exact
          path="/digital-media-advertising"
          element={<DigitalMediaAdvertising />}
        ></Route>
        <Route
          exact
          path="/lead-generation"
          element={<LeadGeneration />}
        ></Route>
        <Route
          exact
          path="/content-writing"
          element={<ContenWriting />}
        ></Route>
        <Route
          exact
          path="/graphic-designing"
          element={<GraphicDesigning />}
        ></Route>
        <Route
          exact
          path="/digital-marketing"
          element={<DigitalMarketing />}
        ></Route>
        <Route
          exact
          path="/web-solutions"
          element={<Website />}
        ></Route>
        {/* <Route exact path="/terms-of-use" element={<TermsOfUse />}></Route> */}
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        {/* <Route exact path="/disclaimer" element={<Disclaimer />}></Route> */}
        <Route exact path="/refund-policy" element={<RefundPolicy />}></Route>

        <Route
          exact
          path="/5-reasons-why-you-need-a-digital-marketing-company"
          element={<BlogOne />}
        ></Route>
        <Route
          exact
          path="/challenges-in-digital-marketing-and-how-to-overcome-them"
          element={<BlogTwo />}
        ></Route>
        <Route
          exact
          path="/the-future-of-e-commerce-and-digital-marketing:navigating-the-next-decade"
          element={<BlogThree />}
        ></Route>
        <Route
          exact
          path="/how-to-build-a-strong-online-brand-presence"
          element={<BlogFour />}
        ></Route>
        <Route
          exact
          path="/the-importance-of-user-generated-content-in-digital-marketing"
          element={<BlogFive />}
        ></Route>
        <Route
          exact
          path="/influencer-marketing-how-to-choose-the-right-partners" element={<BlogSix />}
        ></Route>
        <Route
          exact
          path="/best-strategies-for-successful-email-marketing-campaign"
          element={<BlogSeven />}
        ></Route>
        <Route
          exact
          path="/the-role-of-ai-and-automation-in-digital-marketing"
          element={<BlogEight />}
        ></Route>
        <Route
          exact
          path="/the-impact-of-video-marketing-elevating-engagement-and-driving-results"
          element={<BlogNine />}
        ></Route>
        <Route
          exact
          path="/blogs"
          element={<BlogPage />}
        ></Route>
      </Routes>
    </Provider>
  );
}

export default App;
