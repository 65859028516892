import React, { useState, useEffect } from "react";
import Notiflix from "notiflix";
import { Button, Col, Container, Row } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { IoSync } from "react-icons/io5";
import PostApiCall from "../../POSTAPI";
export default function DigitalForm() {
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    companyname: "",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
      .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 90000000)
        .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  return (
    <>
      <h3 className="fw-bold mb-0">Get a Quote</h3>
      <p>
        Fill out the form and send us a message. We'd love to hear from you!
      </p>
      <Form>
        <Col lg={12}>
          <Form.Control
            className="mb-3"
            type="text"
            placeholder="Company Name"
            name="companyname"
            value={Enquiry.companyname}
            onChange={(e) =>
              setEnquiry({
                ...Enquiry,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Col>
        <Col lg={12}>
          <Form.Control
            className="mb-3"
            type="text"
            placeholder="Name *"
            name="name"
            value={Enquiry.name}
            onChange={(e) =>
              setEnquiry({
                ...Enquiry,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Col>
        <Col lg={12}>
          <Form.Control
            className="mb-3"
            type="text"
            placeholder="Mobile Number *"
            name="mobile"
            value={Enquiry.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </Col>
        <Col lg={12}>
          <Form.Control
            className="mb-3"
            type="email"
            placeholder="Email *"
            name="email"
            value={Enquiry.email}
            onChange={(e) =>
              setEnquiry({
                ...Enquiry,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Col>
        <Col lg={12}>
          <Form.Control
            className="mb-3"
            as="textarea"
            placeholder="Message *"
            style={{ height: "100px" }}
            name="message"
            value={Enquiry.message}
            onChange={(e) =>
              setEnquiry({
                ...Enquiry,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Col>

        <div className="col-12">
          <div className="d-sm-flex d-block">
            <p className="me-2 justify-content-between w-sm-50 w-100 d-sm-flex d-block captcha-bg">
              {captcha_number}
              <span
                onClick={() => {
                  setRotatecaptcha("iorotate");
                  Genratecaptcha();
                }}
                className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
              >
                <IoSync className="aButton fs-3 rotate" />
              </span>
            </p>

            <Form.Control
              type="text"
              className="form-control mb-3"
              name="captcha"
              placeholder="Captcha Code *"
              value={Enquiry.captcha}
              onChange={(e) =>
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </div>
        </div>

        <Col className="d-lg-grid align-items-center">
          <Button
            className="btn btn-primary btn-md talk"
            onClick={() => {
              if (Enquiry.name != "") {
                if (Enquiry.mobile != "") {
                  if (Enquiry.mobile.length == 10) {
                    if (Enquiry.email != "") {
                      if (Enquiry.EmailRegex.test(Enquiry.email)) {
                        if (Enquiry.message != "") {
                          if (
                            Enquiry.captcha.toString() ==
                            captcha_number.toString()
                          ) {
                            Notiflix.Loading.arrows("Please wait...");
                            PostApiCall.postRequest(
                              {
                                name: Enquiry.name,
                                mobile: Enquiry.mobile,
                                email: Enquiry.email,
                                message: Enquiry.message,
                                companyname: Enquiry.companyname,
                                clientid: 1066,
                              },
                              "Enqiury_Mail"
                            ).then((results2) =>
                              results2.json().then((obj2) => {
                                if (
                                  results2.status == 200 ||
                                  results2.status == 201
                                ) {
                                  setLoader(false);
                                  Notiflix.Notify.success(
                                    "Thank you, our team will contact you shortly!"
                                  );
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 2000);
                                } else Notiflix.Loading.remove();
                              })
                            );
                          } else {
                            Notiflix.Notify.failure(
                              "Please enter valid captcha"
                            );
                          }
                        } else {
                          Notiflix.Notify.failure("Please enter message");
                        }
                      } else {
                        Notiflix.Notify.failure("Please enter valid email");
                      }
                    } else {
                      Notiflix.Notify.failure("Please enter email");
                    }
                  } else {
                    Notiflix.Notify.failure("Please enter valid mobile number");
                  }
                } else {
                  Notiflix.Notify.failure("Please enter mobile number");
                }
              } else {
                Notiflix.Notify.failure("Please enter name");
              }
            }}
          >
            {Loader == true ? (
              <div class="d-flex justify-content-center">
                <div
                  class="spinner-border"
                  role="status"
                  style={{ width: "1.4rem", height: "1.4rem" }}
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <span>Send Message </span>
            )}
          </Button>
        </Col>
      </Form>
    </>
  );
}
