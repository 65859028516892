import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import PageBanner from "../../components/PageBanner/PageBanner";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import HeroBanner from "../../assets/img/services-banner/Web-Application-Development.png";
import reasons from "../../assets/img/Blog-page/5-Reasons-Why-You-Need-a-Digital-Marketing-Company.jpg";
import bloginner from "../../assets/img/Blog-page/blog-inner-banner.jpg";

import CommonContactSection from "../Services/CommonContactSection/CommonContactSection";
import RecentBlogSection from "./RecentBlogSection";


export default function BlogOne() {

    return (

        <>
            <Navbar />
            <div className="blog-detail-page-banner">
                <PageBanner bgImage={bloginner} />
            </div>


            <section className="blog-detail-main py-lg-5 py-3">
                {/* <h2 className="main-title main-title-3 wow animate__animated animate__fadeInUp">
                    Blogs
                </h2>
                <p className="main-text fst-italic wow animate__animated animate__fadeInUp">
                    Information Technology Solutions Streamlining your business with
                    the latest technology is the need of the hour.
                </p>
                <p className="main-text wow animate__animated animate__fadeInUp">
                    We are dedicated to ensuring that our clients receive the right
                    solutions. Our robust and qualified team takes the time to
                    understand and analyze your requirements before arriving at a
                    suitable solution.
                </p> */}

            </section>

            <section className="blog-detail-main">
                <div className="container-fluid px-lg-5">
                    <div className="row">
                        <div className="col-lg-8 mb-4">

                            <img
                                src={reasons}
                                className="img-fluid"
                                alt=""
                            />

                            <div className="blog-btn-card p-0 mt-4">
                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                <span>24-09-2024</span>
                            </div>

                            <h2 className="main-title text-start main-title-3 wow animate__animated animate__fadeInUp mb-2 mt-3">
                                5 Reasons Why You Need a Digital Marketing Company

                            </h2>
                            <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                In the current digital era, a company's ability to prosper and grow depends on its online presence. It might be difficult to differentiate yourself from the competition and successfully reach your target audience given the abundance of businesses in every area. This is the point at which a digital marketing agency can really help. For your organization, you should think about using a digital marketing agency for the following five reasons
                            </p>
                            <h5 class="fw-bold wow animate__animated animate__fadeInUp">1. Expertise and Experience</h5>
                            <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                The world of digital marketing is intricate and ever-changing. A professional team with a plethora of expertise and experience in a range of digital marketing tactics, tools, and approaches makes up a digital marketing firm. Working with a digital marketing agency allows you to take advantage of their knowledge to develop successful, results-driven programs that are both targeted and effective.
                            </p>
                            <h5 class="fw-bold wow animate__animated animate__fadeInUp">2. Cost-Effective Solutions</h5>
                            <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                Compared to establishing an internal marketing team, hiring a digital marketing agency is frequently more affordable. For a small portion of the price of hiring full-time staff, you may get access to a variety of services and knowledge by working with a digital marketing company. This enables you to take advantage of premium digital marketing services at a reduced cost.
                            </p>
                            <h5 class="fw-bold wow animate__animated animate__fadeInUp">3. Strategic Planning and Execution</h5>
                            <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                Strategic marketing programs customized to your target demographic and business objectives are the specialty of digital marketing organizations. They can assist you in developing a thorough plan for your digital marketing strategy that covers everything from content production and social media marketing to pay-per-click (PPC) advertising and search engine optimization (SEO). You can make sure that your marketing initiatives are carefully thought out and carried out for optimal effect by collaborating with a digital marketing agency.
                            </p>
                            <h5 class="fw-bold wow animate__animated animate__fadeInUp">4. Advanced Tools and Technologies</h5>
                            <p className="main-text text-start wow animate__animated animate__fadeInUp">
                                Digital marketing firms have access to a variety of modern tools and technology that are costly or impractical for individual enterprises to acquire. These tools aid in data analysis, performance tracking, marketing optimization, and understanding consumer behavior. Utilizing these resources enables you to make data-driven decisions and increase your marketing ROI.
                            </p>
                            <h5 class="fw-bold wow animate__animated animate__fadeInUp">5. Time Savings</h5>
                            <p className="main-text text-start wow animate__animated animate__fadeInUp">

                                Running a business takes time, and managing digital marketing activities while also fulfilling other tasks can be daunting. Outsourcing your digital marketing to a specialized company allows you to devote more time to other vital parts of your organization. The marketing organization manages, monitors, and optimizes your efforts on a daily basis, freeing you up to focus on strategic growth and client happiness.
                            </p>
                            <p className="main-text text-start wow animate__animated animate__fadeInUp">

                                In conclusion, partnering with a digital marketing company is essential for businesses to succeed in today's digital age. They provide expertise, save time and resources, offer customized solutions, and help businesses stay ahead of the competition. If you want to take your business to the next level, consider hiring a digital marketing company today.
                            </p>
                            <p className="main-text text-start wow animate__animated animate__fadeInUp">

                                Are you ready to transform your online presence and drive real results? Our expert team is here to help you unlock your business's full potential. Consider visiting <a href="https://globaltrendz.com/" target="_blank"><span className="date-content">Global Trendz</span></a> and discover how our tailored digital strategies can elevate your brand and boost engagement. Don't miss out on the opportunity to maximize your reach—let's start crafting your success story now!
                            </p>
                        </div>



                        <div className="col-lg-4">
                            <RecentBlogSection />
                        </div>

                    </div>
                </div>
            </section>
            <CommonContactSection />

            <Footer />
        </>
    );
}
