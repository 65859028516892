import React from "react";
import { Accordion, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import { MdDoubleArrow } from "react-icons/md";
import { Link } from "react-router-dom";
import WebapplicationDevelopment from "../../assets/img/Web-Mobile-Solutions/Web-Application-Development.png";
import MobileAppDevelopment from "../../assets/img/Web-Mobile-Solutions/Mobile-App-Development.png";
import Ecatelouge from "../../assets/img/Web-Mobile-Solutions/E-catelouge.png";
import UiUX from "../../assets/img/Web-Mobile-Solutions/Ui-UX.png";
import ProgressiveWebApplications from "../../assets/img/Web-Mobile-Solutions/Progressive-Web-Applications.png";
import WebsiteDesignDevelopment from "../../assets/img/Web-Mobile-Solutions/Website-Design-Development.png";

function WebsiteDevelopment() {
  return (
    <section className="py-lg-5 web-and-mobile">
      <Container fluid className="px-lg-5">
        <Row className="align-items-start justify-content-center">
          <Col lg={10}>
            <h2 className="main-title text-center main-title-3 wow animate__animated animate__fadeInUp mt-lg-0 mt-5">
              Web &amp; Mobile Solutions
            </h2>
            <p className="main-text text-center fst-italic mb-5 wow animate__animated animate__fadeInUp">
              Streamlining your business using the latest technology is the need
              of the hour.
            </p>
          </Col>
        </Row>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={3} className="product-panel-left d-none d-md-block pt-4">
              <Nav variant="pills" className="flex-column gap-1">
                <Nav.Item className="mb-2">
                  <Nav.Link
                    eventKey="first"
                    className="d-flex align-items-center justify-content-between"
                  >
                    Web Application Development
                    <FaChevronRight />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mb-2">
                  <Nav.Link
                    eventKey="second"
                    className="d-flex align-items-center justify-content-between"
                  >
                    Website Design & Development
                    <FaChevronRight />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mb-2">
                  <Nav.Link
                    eventKey="third"
                    className="d-flex align-items-center justify-content-between"
                  >
                    eCatalogues &amp; eBrochures
                    <FaChevronRight />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mb-2">
                  <Nav.Link
                    eventKey="fourth"
                    className="d-flex align-items-center justify-content-between"
                  >
                    Mobile Application Development
                    <FaChevronRight />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mb-2">
                  <Nav.Link
                    eventKey="fifth"
                    className="d-flex align-items-center justify-content-between w-100"
                  >
                    Progressive Web Applications
                    <FaChevronRight />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mb-2">
                  <Nav.Link
                    eventKey="sixth"
                    className="d-flex align-items-center justify-content-between"
                  >
                    UI & UX Design
                    <FaChevronRight />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9} className="d-none d-md-block">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    <Col lg={7} className="px-lg-5">
                      <div className="order-lg-1 order-2">
                        <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                          Automate Regular Tasks
                        </h2>
                        <h2 className="section-highlighter animate__animated animate__fadeIn">
                          Web Application Development
                        </h2>
                        <p className="why-choose-text animate__animated animate__fadeIn">
                          Automating certain parts of your business plays an
                          effective role in enhancing productivity. We enable
                          you to implement this for your organization by
                          understanding your needs and providing the right
                          solutions in developing web-based utility
                          applications.
                        </p>
                        <a
                          href={`/${"web application development".replace(
                            / /g,
                            "-"
                          )}`}
                          className="service-read-more"
                        >
                          <MdDoubleArrow />
                        </a>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="bg-white order-lg-2 order-1">
                        <img
                          src={WebapplicationDevelopment}
                          className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                          alt=""
                        />
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row>
                    <Col lg={7} className="px-lg-5">
                      <div className="order-lg-2 order-2">
                        <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                          Showcase your Business Online
                        </h2>
                        <h2 className="section-highlighter animate__animated animate__fadeIn">
                          Website Design & Development
                        </h2>
                        <p className="why-choose-text animate__animated animate__fadeIn">
                          Establishing an online presence for your business is
                          crucial in today's times. We offer a comprehensive
                          range of web design services for both static and
                          dynamic websites. You can have websites designed for
                          various purposes such as personal, business,
                          corporate, product catalogs, service listings, etc.
                        </p>
                        <a
                          href={`/${"website design and development".replace(
                            / /g,
                            "-"
                          )}`}
                          className="service-read-more"
                        >
                          <MdDoubleArrow />
                        </a>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="bg-white order-lg-1 order-1">
                        <img
                          src={WebsiteDesignDevelopment}
                          className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                          alt=""
                        />
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Row>
                    <Col lg={7} className="px-lg-5">
                      <div className="order-lg-1 order-2">
                        <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                          Digitize your product catalogs
                        </h2>
                        <h2 className="section-highlighter animate__animated animate__fadeIn">
                          eCatalogues & eBrochures
                        </h2>
                        <p className="why-choose-text animate__animated animate__fadeIn">
                          eCatalogues & eBrochures are essential tools for
                          business promotion today. Print brochures are now
                          replaced with eCatalogues, making it easy for you to
                          share with your clients. Showcase your products and
                          services in a creative way and update the content
                          whenever you need to.
                        </p>
                        <a
                          href={`/${"ecatalogues and ebrochures".replace(
                            / /g,
                            "-"
                          )}`}
                          className="service-read-more"
                        >
                          <MdDoubleArrow />
                        </a>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="bg-white order-lg-2 order-1">
                        <img
                          src={Ecatelouge}
                          className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                          alt=""
                        />
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <Row>
                    <Col lg={7} className="px-lg-5">
                      <div className="order-lg-1 order-2">
                        <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                          Adding Mobility to Business
                        </h2>
                        <h2 className="section-highlighter animate__animated animate__fadeIn">
                          Mobile App Development
                        </h2>
                        <p className="why-choose-text animate__animated animate__fadeIn">
                          Using mobile apps to reach your business to clients is
                          very useful for certain business verticals.
                          Additionally, having utility mobile apps for your
                          business helps increase productivity. We specialize in
                          developing hybrid mobile apps for both iOS and Android
                          using the React Native framework.
                        </p>
                        <a
                          href={`/${"mobile application development".replace(
                            / /g,
                            "-"
                          )}`}
                          className="service-read-more"
                        >
                          <MdDoubleArrow />
                        </a>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="bg-white order-lg-2 order-1">
                        <img
                          src={MobileAppDevelopment}
                          className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                          alt=""
                        />
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="fifth">
                  <Row>
                    <Col lg={7} className="px-lg-5">
                      <div className="order-lg-1 order-2">
                        <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                          Experience the App-Like Feel
                        </h2>
                        <h2 className="section-highlighter animate__animated animate__fadeIn">
                          Progressive Web Applications
                        </h2>
                        <p className="why-choose-text animate__animated animate__fadeIn">
                          Progressive Web Applications (PWAs) offer a website
                          that mimics the look and behavior of a mobile app.
                          Capitalizing on native mobile device features, PWAs
                          provide a seamless experience without the need for
                          downloading from an app store. Easily accessible
                          through online searches and web browsers, PWAs bring
                          the convenience of an app without the installation
                          hassle.
                        </p>
                        <a
                          href={`/${"progressive web applications".replace(
                            / /g,
                            "-"
                          )}`}
                          className="service-read-more"
                        >
                          <MdDoubleArrow />
                        </a>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="bg-white order-lg-2 order-1">
                        <img
                          src={ProgressiveWebApplications}
                          className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                          alt=""
                        />
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="sixth">
                  <Row>
                    <Col lg={7} className="px-lg-5">
                      <div className="order-lg-1 order-2">
                        <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                          User-Friendly Interfaces
                        </h2>
                        <h2 className="section-highlighter animate__animated animate__fadeIn">
                          UI & UX Design
                        </h2>
                        <p className="why-choose-text animate__animated animate__fadeIn">
                          Effective User Interface (UI) and User Experience (UX)
                          design are integral components of any website, web
                          app, or mobile app. These aspects define the ease of
                          use and the overall effectiveness of the developed
                          application. Customers who appreciate the look and
                          feel of the product are more likely to stay engaged
                          with your brand.
                        </p>
                        <a
                          href={`/${"ui and ux design".replace(/ /g, "-")}`}
                          className="service-read-more"
                        >
                          <MdDoubleArrow />
                        </a>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="bg-white order-lg-2 order-1">
                        <img
                          src={UiUX}
                          className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                          alt=""
                        />
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
            <Col lg={12} className="d-block d-md-none pt-3 product-panel-left">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Web Application Development
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <img
                        src={WebapplicationDevelopment}
                        className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                        alt=""
                      />
                    </div>
                    <div>
                      <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                        Automate Regular Tasks
                      </h2>
                      <h2 className="section-highlighter animate__animated animate__fadeIn">
                        Web Application Development
                      </h2>
                      <p className="why-choose-text animate__animated animate__fadeIn">
                        Automating certain parts of your business plays an
                        effective role in enhancing productivity. We enable you
                        to implement this for your organization by understanding
                        your needs and providing the right solutions in
                        developing web-based utility applications.
                      </p>
                      <a
                        href={`/${"web application development".replace(
                          / /g,
                          "-"
                        )}`}
                        className="service-read-more"
                      >
                        <MdDoubleArrow />
                      </a>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Website Design & Development
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="bg-white order-lg-1 order-1">
                      <img
                        src={WebsiteDesignDevelopment}
                        className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                        alt=""
                      />
                    </div>
                    <div className="order-lg-2 order-2">
                      <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                        Showcase your Business Online
                      </h2>
                      <h2 className="section-highlighter animate__animated animate__fadeIn">
                        Website Design & Development
                      </h2>
                      <p className="why-choose-text animate__animated animate__fadeIn">
                        Establishing an online presence for your business is
                        crucial in today's times. We offer a comprehensive range
                        of web design services for both static and dynamic
                        websites. You can have websites designed for various
                        purposes such as personal, business, corporate, product
                        catalogs, service listings, etc.
                      </p>
                      <a
                        href={`/${"website design and development".replace(
                          / /g,
                          "-"
                        )}`}
                        className="service-read-more"
                      >
                        <MdDoubleArrow />
                      </a>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    eCatalogues &amp; eBrochures
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="bg-white order-lg-2 order-1">
                      <img
                        src={Ecatelouge}
                        className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                        alt=""
                      />
                    </div>
                    <div className="order-lg-1 order-2">
                      <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                        Digitize your product Catalogues
                      </h2>
                      <h2 className="section-highlighter animate__animated animate__fadeIn">
                        eCatalogues & eBrochures
                      </h2>
                      <p className="why-choose-text animate__animated animate__fadeIn">
                        eCatalogues & eBrochures are essential tools for
                        business promotion today. Print brochures are now
                        replaced with eCatalogues, making it easy for you to
                        share with your clients. Showcase your products and
                        services in a creative way and update the content
                        whenever you need to.
                      </p>
                      <a
                        href={`/${"ecatalogues and ebrochures".replace(
                          / /g,
                          "-"
                        )}`}
                        className="service-read-more"
                      >
                        <MdDoubleArrow />
                      </a>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Mobile Application Development
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="bg-white order-lg-2 order-1">
                      <img
                        src={MobileAppDevelopment}
                        className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                        alt=""
                      />
                    </div>
                    <div className="order-lg-1 order-2">
                      <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                        Adding Mobility to Business
                      </h2>
                      <h2 className="section-highlighter animate__animated animate__fadeIn">
                        Mobile App Development
                      </h2>
                      <p className="why-choose-text animate__animated animate__fadeIn">
                        Using mobile apps to reach your business to clients is
                        very useful for certain business verticals.
                        Additionally, having utility mobile apps for your
                        business helps increase productivity. We specialize in
                        developing hybrid mobile apps for both iOS and Android
                        using the React Native framework.
                      </p>
                      <a
                        href={`/${"mobile application development".replace(
                          / /g,
                          "-"
                        )}`}
                        className="service-read-more"
                      >
                        <MdDoubleArrow />
                      </a>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    Progressive Web Applications
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="bg-white order-lg-2 order-1">
                      <img
                        src={ProgressiveWebApplications}
                        className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                        alt=""
                      />
                    </div>
                    <div className="order-lg-1 order-2">
                      <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                        Experience the App-Like Feel
                      </h2>
                      <h2 className="section-highlighter animate__animated animate__fadeIn">
                        Progressive Web Applications
                      </h2>
                      <p className="why-choose-text animate__animated animate__fadeIn">
                        Progressive Web Applications (PWAs) offer a website that
                        mimics the look and behavior of a mobile app.
                        Capitalizing on native mobile device features, PWAs
                        provide a seamless experience without the need for
                        downloading from an app store. Easily accessible through
                        online searches and web browsers, PWAs bring the
                        convenience of an app without the installation hassle.
                      </p>
                      <a
                        href={`/${"progressive web applications".replace(
                          / /g,
                          "-"
                        )}`}
                        className="service-read-more"
                      >
                        <MdDoubleArrow />
                      </a>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>UI & UX Design</Accordion.Header>
                  <Accordion.Body>
                    <div className="bg-white order-lg-2 order-1">
                      <img
                        src={UiUX}
                        className="img-fluid d-block mx-auto animate__animated animate__zoomIn"
                        alt=""
                      />
                    </div>
                    <div className="order-lg-1 order-2">
                      <h2 className="global-product-title fst-italic animate__animated animate__fadeIn">
                        User-Friendly Interfaces
                      </h2>
                      <h2 className="section-highlighter animate__animated animate__fadeIn">
                        UI & UX Design
                      </h2>
                      <p className="why-choose-text animate__animated animate__fadeIn">
                        Effective User Interface (UI) and User Experience (UX)
                        design are integral components of any website, web app,
                        or mobile app. These aspects define the ease of use and
                        the overall effectiveness of the developed application.
                        Customers who appreciate the look and feel of the
                        product are more likely to stay engaged with your brand.
                      </p>
                      <a
                        href={`/${"ui and ux design".replace(/ /g, "-")}`}
                        className="service-read-more"
                      >
                        <MdDoubleArrow />
                      </a>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </section>
  );
}

export default WebsiteDevelopment;
