import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import PageBanner from "../../../components/PageBanner/PageBanner";
import { Col, Container, Row } from "react-bootstrap";
import "./UiAndUxDesign.css";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import SoftwareDevelopmentImg from "../../../assets/img/software-development-page-image.png";
import HeroBanner from "../../../assets/img/services-banner/UI-UX.png";
import data from "../../../data.json";
import CommonContactSection from "../CommonContactSection/CommonContactSection";
export default function UiAndUxDesign() {
  const [pageData, setPageData] = useState({});
  const [currPage, setCurrPage] = useState("");
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    setPageData({});
    setPageData(
      data[0][
      window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1
      )
      ]
    );
    setCurrPage(
      window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
    );
  });
  return (
    <>
      <Navbar />
      <PageBanner pageData={pageData} bgImage={HeroBanner} />
      <section className="pt-lg-5 pt-3">
        <Container fluid className="position-relative px-lg-5">
          <Row className="align-items-center">
            <Col lg={12} className="text-center">
              <h2 className="main-title text-center main-title-3 wow animate__animated animate__fadeInUp">
                {pageData.pageHeader?.heading}
              </h2>

              <p className="main-text text-center fst-italic wow animate__animated animate__fadeInUp">
                {pageData.pageHeader?.subHeading}
              </p>

              <p className="section-highlighter wow animate__animated animate__fadeInUp">
                {pageData.pageHeader?.description}
              </p>
            </Col>
          </Row>
        </Container>
        <div
          className="bg-white why-us mt-4"
          style={{
            display: pageData.parallexSectionHeading ? "block" : "none",
          }}
        >
          <Container fluid className="px-lg-5">
            <Row>
              <Col lg={12} className="text-center text-white position-relative">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  {pageData.parallexSectionHeading}
                </h2>
                {/* </Col> */}
                <div>
                  <p className="section-highlighter wow animate__animated animate__fadeInUp mb-0">
                    {pageData.parallexSectionDescription}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className={
            pageData.whyChooseSectionImage
              ? "oracle-consulting-section position-relative mt-4"
              : "oracle-consulting-section position-relative mt-4"
          }
        >
          <Container fluid className="px-lg-5"
          // className={pageData.whyChooseSectionImage ? "pt-5" : "pt-0"}
          >
            <Row>
              <Col lg={12}>
                <div className="mb-4 text-center">
                  <h2 className="main-title  main-title-3 wow animate__animated animate__fadeInUp">
                    {pageData.CardSectionOne?.heading}
                  </h2>
                  <p className="main-text wow text-center animate__animated animate__fadeInUp ">
                    {pageData.CardSectionOne?.description}
                  </p>
                </div>
                {/* <Row className="oracle-consul-slider-main">
                  {pageData.CardSectionOne?.cards ? (
                    <MultiCarousel
                      swipeable={true}
                      draggable={true}
                      showDots={false}
                      responsive={responsive}
                      infinite={true}
                      className="pb-5"
                    >
                      {pageData.CardSectionOne?.cards?.map((card) => {
                        return (
                          <div className="carousel-card">
                            <div className="carousel-card-img">
                              <img src={card.img} className="img-fluid" />
                            </div>

                            <div className="carousel-card-content">
                              <h3>{card.heading}</h3>
                              <p>{card.description}</p>
                            </div>
                          </div>
                        );
                      })}
                    </MultiCarousel>
                  ) : (
                    <></>
                  )}
                </Row> */}
              </Col>

              {pageData.CardSectionOne?.cards?.map((card) => {
                return (
                  <Col lg={4} className="mb-5">
                    <div className="carousel-card">
                      <div className="carousel-card-content">
                        <img className="carousel-card-icon" src={card.icon} />
                        <h3>{card.heading}</h3>
                        <p>{card.description}</p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        <Container fluid className="px-lg-5"
          style={{ display: pageData.CardSectionTwo ? "block" : "none" }}
        >
          <Row>
            <Col lg={12}>
              <h2 className="main-title wow animate__animated animate__fadeInUp text-center mb-4">
                {pageData.CardSectionTwo?.heading}
              </h2>
              <p className="main-text wow text-center animate__animated animate__fadeInUp">
                {pageData.CardSectionTwo?.description}
              </p>
              <Row>
                {pageData.CardSectionTwo?.cards?.map((card) => {
                  return (
                    <Col lg={4} className="mb-5">
                      <div className="carousel-card">
                        <div className="carousel-card-content">
                          <img className="carousel-card-icon" src={card.icon} />
                          <h3>{card.heading}</h3>
                          <p>{card.description}</p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <CommonContactSection />
      <Footer />
    </>
  );
}
