import React, { useState } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";

function WhyUs() {
  return (
    <section className="bg-white why-us">
      <Container fluid className="position-relative px-lg-5">
        <Row className="align-items-center justify-content-center">
          <Col lg={10} className="text-center text-white">
            <h2 className="main-title mb-lg-5">Why choose us</h2>
            <p className="main-text text-center">
              We are a company with extensive experience and a robust technical
              and business analysis team, offering a unique blend of
              communication and technology skills. This combination helps our
              clients achieve their business objectives, driving us to strive
              for better results for our customers.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default WhyUs;
