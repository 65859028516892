import React, { useState, useEffect } from "react";
import Notiflix from "notiflix";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import PostApiCall from "../../POSTAPI";
import {
  Col,
  Container,
  Row,
  Button,
  Modal,
  Accordion,
  Offcanvas,
} from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import PageBanner from "../../components/PageBanner/PageBanner";
import "./Careers.css";
import WorkWithUsPageBanner from "../../assets/img/services-banner/work-with-us-page-banner.png";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoLocationOutline, IoSync } from "react-icons/io5";
import CommonContactSection from "../Services/CommonContactSection/CommonContactSection";

function Careers() {
  const [show, setShow] = useState(false);

  // Job form integration
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);

  // fileupload
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileBase64String, setfileBase64String] = useState("");
  const onFileChange = (e) => {
    if (e.target.files[0].type == "application/pdf") {
      setSelectedFile(e.target.files);
    } else {
      Notiflix.Notify.failure("file should be in pdf format");
    }
  };

  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64String(attachment);
        // console.log(setfileBase64String);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64(selectedFile[0]);

  // console.log(selectedFile[0]);
  const [Enquiry, setEnquiry] = useState({
    job: "",
    name: "",
    mobile: "",
    email: "",
    age: "",
    message: "",
    location: "",
    attachment: "",
    country: "",
    postapplied: "",
    qualification: "",
    careernutshell: "",
    strongsuits: "",
    profachievements: "",
    jobleavingreason: "",
    otherinfo: "",
    File: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 90000000)
        .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
      .toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }

  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);

  const handleClose = () => setShow(false);

  const handleShowJobTitle = (jobDetails) => {
    setEnquiry({
      ...Enquiry,
      job: jobDetails.title,
    });
    setShow(true);
  };

  return (
    <>
      <Navbar />
      <PageBanner bgImage={WorkWithUsPageBanner} />
      <section className="py-lg-5 py-4">
        <Container fluid className="position-relative px-lg-5">
          <Row>
            <Col lg={12} className="text-center mb-4 mb-lg-5">
              <p className="main-text text-center fst-italic wow animate__animated animate__fadeInUp">
                Work at the most dynamic company & unlock your true potential.
              </p>
              <p className="main-text text-center wow animate__animated animate__fadeInUp">
                Check out our interest forms below and register your details for
                the one that best fits your skills, experience, and interests.
              </p>
            </Col>
          </Row>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div>
                  <div>
                    <p>
                      ReactJS Developer (Primary skills: ReactJS | Experience:
                      min 2 years)
                    </p>
                  </div>
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-1">
                      <HiOutlineBuildingOffice2 />
                      <p className="mb-0 fw-normal">Global Trendz</p>
                    </div>
                    <div className="d-flex gap-1">
                      <IoLocationOutline />
                      <p className="mb-0 fw-normal">New Delhi</p>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <p>
                    We are looking for a skilled React.js Developer having 2
                    years of experience to join our front-end development team.
                    In this role, you will be responsible for developing and
                    implementing user interface components using React.js
                    concepts and workflows such as Redux. You will also be
                    responsible for profiling and improving front-end
                    performance and documenting our front-end codebase.
                  </p>
                  <p>
                    To ensure success as a React.js Developer, you should have
                    in-depth knowledge of JavaScript and React concepts,
                    excellent front-end coding skills, and a good understanding
                    of progressive web applications. Ultimately, a top-class
                    React.js Developer should be able to design and build modern
                    user interface components to enhance application
                    performance.
                  </p>
                  <h6 className="fw-bold">Responsibilities</h6>
                  <ul>
                    <li>
                      Developing and implementing highly-responsive user
                      interface components using React concepts.
                    </li>
                    <li>
                      Developing and implementing front-end architecture to
                      support user interface concepts.
                    </li>
                    <li>
                      Building reusable components and front-end libraries for
                      future use.
                    </li>
                    <li>
                      Translating PSDs and wireframes into high quality code.
                    </li>
                    <li>
                      Optimizing components for maximum performance across a
                      vast array of web-capable devices and browsers.
                    </li>
                    <li>
                      Meeting with the development team to discuss user
                      interface ideas and applications
                    </li>
                    <li>Monitoring and improving front-end performance.</li>
                    <li>
                      Documenting application changes and developing updates
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">Requirements</h6>
                  <ul>
                    <li>
                      Strong proficiency in JavaScript, including DOM
                      manipulation and the JavaScript object model.
                    </li>
                    <li>
                      Thorough understanding of React.js and its core
                      principles.
                    </li>
                    <li>
                      Experience with popular React.js workflows (such as Flux
                      or Redux).
                    </li>
                    <li>
                      Preferred to have experience in Javascript, CSS, HTML, and
                      Photoshop (any wireframing tool).
                    </li>
                    <li>
                      Experience with data structure libraries
                      (e.g.Immutable.js).
                    </li>
                    <li>2 years of experience as a react developer.</li>
                    <li>Familiarity with RESTful APIs.</li>
                    <li>
                      Familiarity with code versioning tools such as Git, SVN,
                      and Mercurial.
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">
                    Technical and Professional Requirements:
                  </h6>
                  <ul>
                    <li>Primary skills: ReactJS , Redux or Flux</li>
                  </ul>
                </div>
                <a
                  // onClick={() =>
                  //   handleShowJobTitle({ title: "ReactJS Developer" })
                  // }
                  // className="btn btn-primary talk"
                  className="hiring-close"
                >
                  {/* Apply */}
                  Closed
                </a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <div>
                  <div className="">
                    <p>
                      Android Developer (Primary skills: Java, Kotlin or C++ |
                      Experience: 1-2 years)
                    </p>
                  </div>
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-1">
                      <HiOutlineBuildingOffice2 />
                      <p className="mb-0 fw-normal">Global Trendz</p>
                    </div>
                    <div className="d-flex gap-1">
                      <IoLocationOutline />
                      <p className="mb-0 fw-normal">Chennai</p>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <h6 className="fw-bold">Responsibilities</h6>
                  <ul>
                    <li>
                      Conceptualizing and formulating apps that are suitable for
                      use on all types of Android devices.
                    </li>
                    <li>Implementing measures to safeguard users' data.</li>
                    <li>
                      Ensuring that the construction and presentation of your
                      apps are congruent with the company's standards.
                    </li>
                    <li>
                      Proofreading your code and correcting mistakes before each
                      app is released.
                    </li>
                    <li>
                      Collaborating with UI and UX Designers, as well as
                      Software Testers, to ensure that each app is presentable
                      and in perfect working order.
                    </li>
                    <li>
                      Liaising with the marketing department to ensure
                      consistency in our company's 'voice' across the board.
                    </li>
                    <li>
                      Monitoring app reviews to detect areas for improvement.
                    </li>
                    <li>
                      Creating app updates, including bug fixes and additional
                      features, for release.
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">Requirements</h6>
                  <ul>
                    <li>
                      Degree in software development, computer science, or
                      similar.
                    </li>
                    <li>Proficiency in Java, Kotlin, or C++.</li>
                    <li>
                      Ability to use the Android Studio, including the Android
                      SDK, with ease.
                    </li>
                    <li>
                      Must have 1-2 years of experience as an android developer.
                    </li>
                    <li>Excellent coding and proofreading skills.</li>
                    <li>Top-notch teamwork and communication skills.</li>
                    <li>
                      Ability to manage your workload with minimal supervision.
                    </li>
                    <li>Unwavering curiosity.</li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">
                    Technical and Professional Requirements:
                  </h6>
                  <ul>
                    <li>Primary skills: Java, Kotlin, or C++</li>
                  </ul>
                </div>
                <a
                  // onClick={() =>
                  //   handleShowJobTitle({ title: "Android Developer" })
                  // }
                  // className="btn btn-primary talk"
                  className="hiring-close"
                >
                  {/* Apply */}
                  Closed
                </a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <div>
                  <div className="">
                    <p>
                      Web Designer (Primary skills: JavaScript, HTML, and CSS |
                      Experience: 1-2 years)
                    </p>
                  </div>
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-1">
                      <HiOutlineBuildingOffice2 />
                      <p className="mb-0 fw-normal">Global Trendz</p>
                    </div>
                    <div className="d-flex gap-1">
                      <IoLocationOutline />
                      <p className="mb-0 fw-normal">New Delhi</p>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <h6 className="fw-bold">Responsibilities</h6>
                  <ul>
                    <li>
                      Create and maintain user interfaces for web applications
                      and websites.
                    </li>
                    <li>
                      Develop responsive interactive technology for dynamic web
                      pages such as menu buttons, online forms, etc.
                    </li>
                    <li>Test and troubleshoot interface software.</li>
                    <li>
                      Construct visualizations that are able to depict vast
                      amounts of data.
                    </li>
                    <li>
                      Build reusable components and front-end libraries for
                      future use.
                    </li>
                    <li>
                      Optimize components for maximum performance across a vast
                      array of web-capable devices and browsers.
                    </li>
                    <li>
                      Collaborate with other developer teams to discuss user
                      interface applications and ideas.
                    </li>
                    <li>
                      Work closely with clients, web designers, and project
                      managers to create strong and effective applications.
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">Requirements</h6>
                  <ul>
                    <li>Strong proficiency in JavaScript, HTML, and CSS.</li>
                    <li>
                      Extensive knowledge of ReactJS, JSX, Babel, data
                      structures, and algorithms.
                    </li>
                    <li>
                      Excellent problem-solving and troubleshooting skills.
                    </li>
                    <li>
                      Excellent time management and project management skills.
                    </li>
                    <li>
                      Team player with excellent verbal and written
                      communication skills.
                    </li>
                    <li>
                      Ability to understand business requirements and translate
                      them into technical requirements.
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">
                    Technical and Professional Requirements:
                  </h6>
                  <ul>
                    <li>Primary skills: JavaScript, HTML, and CSS</li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">Preferred Skills:</h6>
                  <ul>
                    <li>
                      ReactJS, JSX, Babel, data structures, and algorithms
                    </li>
                  </ul>
                </div>
                <a
                  // onClick={() => handleShowJobTitle({ title: "Web Designer" })}
                  // className="btn btn-primary talk"
                  className="hiring-close"
                >
                  {/* Apply */}
                  Closed
                </a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <div>
                  <div className="">
                    <p>
                      Content Writer (Primary skills: | Experience: 1-2 years)
                    </p>
                  </div>
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-1">
                      <HiOutlineBuildingOffice2 />
                      <p className="mb-0 fw-normal">Global Trendz</p>
                    </div>
                    <div className="d-flex gap-1">
                      <IoLocationOutline />
                      <p className="mb-0 fw-normal">New Delhi</p>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Global Trendz is seeking a Content Writer Intern who will be
                  responsible for creating engaging and informative content for
                  various platforms, including websites, blogs, social media,
                  and other digital marketing channels. This internship offers a
                  fantastic opportunity for individuals looking to develop their
                  writing skills, learn about content marketing, and stay
                  updated with the latest trends.
                </p>
                <div>
                  <h6 className="fw-bold">Responsibilities</h6>
                  <ul>
                    <li>
                      Content Creation: Generate high-quality and original
                      content that aligns with the company's branding and
                      messaging. This may include articles, blog posts, social
                      media posts, and other written materials.
                    </li>
                    <li>
                      Research: Conduct research on industry trends, market
                      developments, and relevant topics to ensure the accuracy
                      and relevancy of the content.
                    </li>
                    <li>
                      SEO Optimization: Learn and implement basic search engine
                      optimization (SEO) techniques to enhance the visibility of
                      content in search engine results.
                    </li>
                    <li>
                      Proofreading and Editing: Review and edit your own work to
                      ensure it is error-free and meets the company's standards
                      for grammar, style, and formatting.
                    </li>
                    <li>
                      Content Calendar: Collaborate with the content team to
                      follow a content calendar and meet deadlines for content
                      publication.
                    </li>
                    <li>
                      Social Media Engagement: Participate in social media
                      management and engage with the audience by responding to
                      comments, messages, and sharing content.
                    </li>
                    <li>
                      Content Promotion: Assist in promoting content through
                      various online channels, such as email newsletters and
                      social media platforms.
                    </li>
                    <li>
                      Learning and Growth: Keep up to date with industry trends,
                      content marketing strategies, and digital marketing
                      techniques to continually improve your skills.
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">Qualifications</h6>
                  <ul>
                    <li>
                      Currently pursuing a degree in English, journalism,
                      marketing, or a related field (or recent graduate).
                    </li>
                    <li>
                      Strong written communication skills and an ability to
                      adapt your writing style to different audiences.
                    </li>
                    <li>
                      A passion for staying informed about current trends and
                      developments in various industries.
                    </li>
                    <li>
                      Basic knowledge of SEO and content optimization principles
                      is a plus.
                    </li>
                    <li>
                      Creativity and a willingness to learn and adapt to new
                      challenges.
                    </li>
                  </ul>
                </div>
                <a
                  // onClick={() =>
                  //   handleShowJobTitle({ title: "Content Writer" })
                  // }
                  // className="btn btn-primary talk"
                  className="hiring-close"
                >
                  {/* Apply */}
                  Closed
                </a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <div>
                  <div className="">
                    <p>Graphic Designer (Experience: 1 year)</p>
                  </div>
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-1">
                      <HiOutlineBuildingOffice2 />
                      <p className="mb-0 fw-normal">Global Trendz</p>
                    </div>
                    <div className="d-flex gap-1">
                      <IoLocationOutline />
                      <p className="mb-0 fw-normal">New Delhi</p>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  We are seeking a new graphic designer to join our team. You
                  will be designing a wide variety of things across digital and
                  offline media. To be successful in this position, you’ll be a
                  self-starter, capable of delivering brilliant creative ideas,
                  and show amazing attention to detail.
                </p>
                <div>
                  <h6 className="fw-bold">Responsibilities</h6>
                  <ul>
                    <li>
                      Planning concepts by studying relevant information and
                      materials.
                    </li>
                    <li>
                      Illustrating concepts by designing examples of art
                      arrangement, size, type size and style and submitting them
                      for approval.
                    </li>
                    <li>
                      Preparing finished art by operating necessary equipment
                      and software.
                    </li>
                    <li>
                      Contributing to team efforts by accomplishing tasks as
                      needed.
                    </li>
                    <li>Communicating with clients about layout and design.</li>
                    <li>
                      Creating a wide range of graphics and layouts for product
                      illustrations, company logos, and websites with software
                      such as photoshop.
                    </li>
                    <li>
                      Reviewing final layouts and suggesting improvements when
                      necessary.
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">Requirements</h6>
                  <ul>
                    <li>Bachelor’s degree.</li>
                    <li>
                      Experience as a graphic designer or in a related field.
                    </li>
                    <li>
                      Demonstrable graphic design skills with a strong
                      portfolio.
                    </li>
                    <li>
                      Proficiency with required desktop publishing tools,
                      including Photoshop, InDesign Quark, and Illustrator.
                    </li>
                    <li>A strong eye for visual composition.</li>
                    <li>
                      Effective time management skills and the ability to meet
                      deadlines.
                    </li>
                    <li>Able to give and receive constructive criticism.</li>
                    <li>
                      Understanding of marketing, production, website design,
                      corporate identity, product packaging, advertisements, and
                      multimedia design.
                    </li>
                    <li>Experience with computer-aided design.</li>
                  </ul>
                </div>
                <a
                  // onClick={() =>
                  //   handleShowJobTitle({ title: "Graphic Designer" })
                  // }
                  // className="btn btn-primary talk"
                  className="hiring-close"
                >
                  Closed
                  {/* Apply */}
                </a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <div>
                  <div className="">
                    <p>UI/UX Designer (Experience: 1 year )</p>
                  </div>
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-1">
                      <HiOutlineBuildingOffice2 />
                      <p className="mb-0 fw-normal">Global Trendz</p>
                    </div>
                    <div className="d-flex gap-1">
                      <IoLocationOutline />
                      <p className="mb-0 fw-normal">New Delhi</p>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <h6 className="fw-bold">Responsibilities</h6>
                  <ul>
                    <li>
                      Creating user-centered designs by understanding business
                      requirements, and user feedback.
                    </li>
                    <li>
                      Create wireframes, prototypes, and user flows to visualize
                      and communicate design concepts and functionality to
                      stakeholders.
                    </li>
                    <li>
                      Translating requirements into style guides, design
                      systems, design patterns and attractive user interfaces.
                    </li>
                    <li>
                      Designing UI elements such as input controls, navigational
                      components and informational components.
                    </li>
                    <li>
                      Creating original graphic designs (e.g. images, sketches
                      and tables).
                    </li>
                    <li>
                      Identifying and troubleshooting UX problems (e.g.
                      responsiveness).
                    </li>
                    <li>
                      Collaborating effectively with product, engineering, and
                      management teams.
                    </li>
                    <li>
                      Incorporating customer feedback, usage metrics, and
                      usability findings into design in order to enhance user
                      experience.
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">Requirements</h6>
                  <ul>
                    <li>
                      A bachelor's degree and a minimum of 4-5 years UI/UX
                      design experience for digital products or services mainly
                      in ecommerce.
                    </li>
                    <li>
                      A portfolio of professional UI/UX design work for both web
                      and mobile platforms.
                    </li>
                    <li>
                      Working knowledge of the following technologies and
                      software: Sketch, InVision, Visio, HTML, CSS (SCSS), iOS,
                      Android, Design Systems, and Adobe Creative Suite.
                    </li>
                    <li>A team player but can work independently too.</li>
                    <li>Excellent written and verbal communication skills.</li>
                    <li>
                      Multi-tasking and time-management skills, with the ability
                      to prioritize tasks.
                    </li>
                  </ul>
                </div>
                <a
                  // onClick={() =>
                  //   handleShowJobTitle({ title: "UI/UX Designer" })
                  // }
                  // className="btn btn-primary talk"
                  className="hiring-close"
                >
                  {/* Apply */}
                  Closed
                </a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <div>
                  <div className="">
                    <p>Social Media Marketing ( Experience: 2 years )</p>
                  </div>
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-1">
                      <HiOutlineBuildingOffice2 />
                      <p className="mb-0 fw-normal">Global Trendz</p>
                    </div>
                    <div className="d-flex gap-1">
                      <IoLocationOutline />
                      <p className="mb-0 fw-normal">New Delhi</p>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <h6 className="fw-bold">Responsibilities</h6>
                  <ul>
                    <li>
                      Build and execute social media strategy through
                      competitive research, platform determination,
                      benchmarking, messaging and audience identification.
                    </li>
                    <li>
                      Generate, edit, publish and share daily content (original
                      text, images, video or HTML) that builds meaningful
                      connections and encourages community members to take
                      action.
                    </li>
                    <li>
                      Set up and optimize company pages within each platform to
                      increase the visibility of the company's social content.
                    </li>
                    <li>
                      Moderate all user-generated content in line with the
                      moderation policy for each community.
                    </li>
                    <li>
                      Create editorial calendars and syndication schedules.
                    </li>
                    <li>
                      Continuously improve by capturing and analyzing the
                      appropriate social data/metrics, insights and best
                      practices, and then acting on the information.
                    </li>
                    <li>
                      Collaborate with other departments (customer relations,
                      sales etc) to manage reputation, identify key players and
                      coordinate actions.
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">Requirements</h6>
                  <ul>
                    <li>
                      Proven working experience in social media marketing or as
                      a Digital Media Specialist.
                    </li>
                    <li>
                      Excellent consulting, writing, editing (photo/video/text),
                      presentation and communication skills.
                    </li>
                    <li>
                      Demonstrable social networking experience and social
                      analytics tools knowledge.
                    </li>
                    <li>
                      Adequate knowledge of web design, web development, CRO and
                      SEO.
                    </li>
                    <li>
                      Knowledge of online marketing and good understanding of
                      major marketing channels.
                    </li>
                    <li>Bachelor's degree is required.</li>
                  </ul>
                </div>
                <a
                  // onClick={() =>
                  //   handleShowJobTitle({ title: "Social Media Marketing" })
                  // }
                  // className="btn btn-primary talk"
                  className="hiring-close"
                >
                  {/* Apply */}
                  Closed
                </a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                <div>
                  <div className="">
                    <p>PPC or Google Ad Executive ( Experience: 1 year )</p>
                  </div>
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-1">
                      <HiOutlineBuildingOffice2 />
                      <p className="mb-0 fw-normal">Global Trendz</p>
                    </div>
                    <div className="d-flex gap-1">
                      <IoLocationOutline />
                      <p className="mb-0 fw-normal">New Delhi</p>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <h6 className="fw-bold">Responsibilities</h6>
                  <ul>
                    <li>
                      Manage and optimize Google Ads campaigns to achieve the
                      client's marketing objectives, including keyword research,
                      ad creation, campaign setup, bidding, and ongoing
                      optimization.
                    </li>
                    <li>
                      Develop and execute digital marketing strategies to drive
                      website traffic, increase leads, and improve conversion
                      rates.
                    </li>
                    <li>
                      Conduct comprehensive performance analysis of Google Ads
                      campaigns, provide insights, and make data-driven
                      recommendations to enhance campaign performance.
                    </li>
                    <li>
                      Collaborate with internal teams, clients, and stakeholders
                      to understand business goals and develop effective Google
                      Ads strategies that align with their objectives.
                    </li>
                    <li>
                      Stay up-to-date with the latest digital marketing trends,
                      Google Ads best practices, and industry changes to ensure
                      our campaigns are cutting-edge and effective.
                    </li>
                    <li>
                      Provide exceptional customer service, maintaining regular
                      communication with clients, addressing inquiries, and
                      providing performance reports to ensure client
                      satisfaction and retention.
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">Requirements</h6>
                  <ul>
                    <li>
                      Bachelor's degree in Marketing, Advertising, or a related
                      field.
                    </li>
                    <li>
                      Proven experience managing and optimizing Google Ads
                      campaigns, with a strong understanding of the Google Ads
                      platform and features.
                    </li>
                    <li>
                      Proficient in Google Ads tools, including Google Ads
                      Editor, Google Analytics, and Google Tag Manager.
                    </li>
                    <li>
                      Excellent analytical skills with the ability to analyze
                      data, identify trends, and make data-driven decisions.
                    </li>
                    <li>
                      Strong communication skills, both written and verbal, with
                      the ability to effectively communicate complex ideas and
                      strategies to clients and internal teams.
                    </li>
                    <li>
                      Ability to work independently and manage multiple
                      campaigns and deadlines in a fast-paced environment.
                    </li>
                    <li>Google Ads certification is a plus.</li>
                  </ul>
                </div>
                <a
                  // onClick={() =>
                  //   handleShowJobTitle({
                  //     title: "PPC or Google Ad Executive",
                  //   })
                  // }
                  // className="btn btn-primary talk"
                  className="hiring-close"
                >
                  Closed
                  {/* Apply */}
                </a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                <div>
                  <div className="">
                    <p>Sales Executive ( Experience: 1 year )</p>
                  </div>
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-1">
                      <HiOutlineBuildingOffice2 />
                      <p className="mb-0 fw-normal">Global Trendz</p>
                    </div>
                    <div className="d-flex gap-1">
                      <IoLocationOutline />
                      <p className="mb-0 fw-normal">New Delhi</p>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  We are a Web Development & Brand Management company based in
                  Chennai and New Delhi, INDIA. We deliver Web Development &
                  Branding services to clients in India and around the world.
                </p>
                <p>
                  Providing services for over 20 years now we have clients
                  spanning over various Industries like Hospitality,
                  Manufacturing Sectors, Trading & Marketing Services, Travel &
                  Logistics, Educational Institutions, Charity Organizations,
                  Human Resources, Pharmaceuticals, Event Management, Exporters
                  & Importers, Individual Professionals, Consultants, Apparel &
                  Textiles, Interior Designers & Architects etc.
                </p>
                <div>
                  <h6 className="fw-bold">Responsibilities</h6>
                  <ul>
                    <li>
                      Client Relationship Management: Cultivate and maintain
                      positive relationships with existing clients by providing
                      exceptional service and support.
                    </li>
                    <li>
                      Business Development: Identify and engage with potential
                      clients through various channels, including cold calls,
                      emails, networking, and industry events.
                    </li>
                    <li>
                      Needs Assessment: Understand the requirements and pain
                      points of clients, and present solutions from Global
                      Trendz that meet their specific needs.
                    </li>
                    <li>
                      Product Knowledge: Stay well-informed about Global
                      Trendz's products or services to effectively communicate
                      their features and benefits to clients.
                    </li>
                    <li>
                      Sales Presentations: Create and deliver persuasive sales
                      presentations to demonstrate the value of our offerings
                      and how they address client challenges.
                    </li>
                    <li>
                      Proposal Generation: Prepare and submit proposals, quotes,
                      and contracts to clients, following the company's
                      guidelines.
                    </li>
                    <li>
                      Negotiation and Closing: Negotiate terms and close deals,
                      ensuring both the client's and the company's objectives
                      are met.
                    </li>
                    <li>
                      Sales Targets: Work toward achieving or exceeding sales
                      targets and providing regular reports on sales progress to
                      the sales manager.
                    </li>
                    <li>
                      Sales Records: Maintain accurate and up-to-date records of
                      client interactions, sales activities, and leads in the
                      CRM system.
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="fw-bold">Requirements</h6>
                  <ul>
                    <li>
                      Proven experience in sales, preferably in a relevant
                      industry.
                    </li>
                    <li>Strong interpersonal and communication skills.</li>
                    <li>Ability to build and maintain client relationships.</li>
                    <li>
                      A customer-centric approach and a keen understanding of
                      client needs.
                    </li>
                    <li>Negotiation and problem-solving skills.</li>
                    <li>
                      Self-motivation, determination, and a results-oriented
                      mindset.
                    </li>
                  </ul>
                  <h6 className="fw-bold">Benefits</h6>
                  <ul>
                    <li>
                      Comprehensive training and ongoing professional
                      development opportunities.
                    </li>
                    <li>
                      Competitive commission structure based on performance.
                    </li>
                    <li>
                      Opportunities for career advancement and growth within the
                      company.
                    </li>
                    <li>
                      Exposure to a dynamic and innovative work environment.
                    </li>
                    <li>
                      The chance to contribute to the success and growth of
                      Global Trendz.
                    </li>
                  </ul>
                </div>
                <a
                  // onClick={() =>
                  //   handleShowJobTitle({ title: "Sales Executive" })
                  // }
                  // className="btn btn-primary talk"
                  className="hiring-close"
                >
                  Closed
                  {/* Apply */}
                </a>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section>
      <CommonContactSection />
      <Footer />
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Apply for Job</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingInput"
                label="Job Title *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Job Title"
                  name="job"
                  value={Enquiry.job}
                  onChange={(e) =>
                    setEnquiry({ ...Enquiry, [e.target.job]: e.target.value })
                  }
                  disabled
                />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingInput"
                label="Name *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={Enquiry.name}
                  onChange={(e) =>
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingPassword"
                label="Mobile Number *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Mobile Number *"
                  name="mobile"
                  value={Enquiry.mobile}
                  onChange={(e) => {
                    if (e.target.value.length <= 10)
                      setEnquiry({
                        ...Enquiry,
                        [e.target.name]: e.target.value.replace(/\D/g, ""),
                      });
                  }}
                />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingPassword"
                label="Email"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={Enquiry.email}
                  onChange={(e) =>
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </Col>
            <Col lg={12} className="mb-3">
              <Form.Label className="small mb-1">
                Attach your CV (Accepted file format .pdf,.doc,.docx)
              </Form.Label>
              <Form.Control type="file" size="sm" onChange={onFileChange} />
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Message"
                className="mb-3"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a message here"
                  style={{ height: "100px" }}
                  name="message"
                  value={Enquiry.message}
                  onChange={(e) =>
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </Col>
            <div className="col-12">
              <div className="d-sm-flex d-block">
                <p className="me-2 justify-content-between w-sm-50 w-100 d-sm-flex d-block captcha-bg">
                  {captcha_number}
                  <span
                    onClick={() => {
                      setRotatecaptcha("iorotate");
                      Genratecaptcha();
                    }}
                    className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
                  >
                    <IoSync className="aButton fs-4 rotate" />
                  </span>
                </p>

                <FloatingLabel
                  controlId="floatingPassword"
                  label="Captcha Code *"
                  className="mb-3 w-100"
                >
                  <Form.Control
                    type="text"
                    className="form-control w-100"
                    name="captcha"
                    placeholder="Captcha Code *"
                    value={Enquiry.captcha}
                    onChange={(e) =>
                      setEnquiry({
                        ...Enquiry,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </div>
            </div>
            <Col className="d-lg-flex justify-content-end align-items-center">
              <Button
                className="btn btn-primary talk w-100"
                onClick={() => {
                  if (Enquiry.name != "") {
                    if (Enquiry.mobile != "") {
                      if (Enquiry.mobile.length == 10) {
                        if (Enquiry.email != "") {
                          if (Enquiry.EmailRegex.test(Enquiry.email)) {
                            if (selectedFile.length != 0) {
                              if (
                                Enquiry.captcha.toString() ==
                                captcha_number.toString()
                              ) {
                                Notiflix.Loading.arrows("Please wait...");
                                PostApiCall.postRequest(
                                  {
                                    job: Enquiry.job,
                                    name: Enquiry.name,
                                    mobile: Enquiry.mobile,
                                    email: Enquiry.email,
                                    message: Enquiry.message,
                                    age: null,
                                    location: "",
                                    country: "",
                                    postapplied: Enquiry.job,
                                    qualification: "",
                                    careernutshell: "",
                                    strongsuits: "",
                                    profachievements: "",
                                    jobleavingreason: "",
                                    otherinfo: "",
                                    attachment: fileBase64String,
                                    clientid: 1053,
                                    //clientid: 1020,
                                  },
                                  "jobapplicationsgt"
                                ).then((results2) =>
                                  results2.json().then((obj2) => {
                                    if (
                                      results2.status == 200 ||
                                      results2.status == 201
                                    ) {
                                      Notiflix.Notify.success(
                                        "Thank you, our team will contact you shortly!"
                                      );
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 2000);
                                    } else Notiflix.Loading.remove();
                                  })
                                );
                              } else {
                                Notiflix.Notify.failure(
                                  "Please enter valid captcha"
                                );
                              }
                            } else {
                              Notiflix.Notify.failure(
                                "Please upload resume in pdf format"
                              );
                            }
                          } else {
                            Notiflix.Notify.failure("Please enter valid email");
                          }
                        } else {
                          Notiflix.Notify.failure("Please enter email");
                        }
                      } else {
                        Notiflix.Notify.failure(
                          "Please enter valid mobile number"
                        );
                      }
                    } else {
                      Notiflix.Notify.failure("Please enter mobile number");
                    }
                  } else {
                    Notiflix.Notify.failure("Please enter name");
                  }
                }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Careers;
