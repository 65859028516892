import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import "./WhatsApp.css";
import WorkWithUsPageBanner from "../../assets/img/services-banner/technologies-banner.png";
import PageBanner from "../../components/PageBanner/PageBanner";
export default function WhatsApp() {
  return (
    <>
      <Navbar />
      <PageBanner bgImage={WorkWithUsPageBanner} />
      <div className="blank-section"></div>
      <Footer />
    </>
  );
}
