import React, { useState } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import MultiCarousel from "react-multi-carousel";
import {
  AiOutlineBgColors,
  AiOutlineCopy,
  AiOutlineDashboard,
  AiOutlineFund,
  AiOutlineFundProjectionScreen,
  AiOutlineInstagram,
  AiOutlineMessage,
} from "react-icons/ai";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import SEO from "../../assets/img/home-service-Icons/SEO.png";
import SEM from "../../assets/img/home-service-Icons/SEM.png";
import SMM from "../../assets/img/home-service-Icons/SMM.png";
import DigitalMarketingServices from "../../assets/img/home-service-Icons/Digital-Marketing-Services.png";
import LeadGeneration from "../../assets/img/home-service-Icons/Lead-Generation.png";
import ContentWriting from "../../assets/img/home-service-Icons/Content-Writing.png";
import GraphicDesign from "../../assets/img/home-service-Icons/Graphic-Design.png";
import Blogs from "../../assets/img/home-service-Icons/Blogs.png";
function Services() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section className="py-lg-5 bg-dark">
      <Container fluid className="py-lg-5 py-5 px-lg-5">
        <Row className="align-items-center justify-content-center">
          <Col lg={6} className="text-start text-white">
            <h2 className="main-title mb-4 wow animate__animated animate__fadeInUp">
              Digital Marketing Services & Solutions
            </h2>
            <p className="main-text wow animate__animated animate__fadeInUp">
              The utilization of social media and online search represents the
              present and future of marketing solutions. In contrast to
              traditional marketing, this approach provides broader reach and
              enables precise targeting of clients.
            </p>
          </Col>

          <Col
            lg={3}
            className="mb-lg-0 mb-2 position-relative d-none d-md-block"
          >
            <div className="service-wrapper">
              <Card className="services shadow border-0">
                <Card.Body>
                  <Card.Title className="wow animate__animated animate__fadeInUp count">
                    01
                  </Card.Title>
                  <Card.Title className="wow animate__animated animate__fadeInUp">
                    Search Engine Optimization (SEO)
                  </Card.Title>
                  {/* <AiOutlineFundProjectionScreen className="services-icon-lg" /> */}
                  <img src={SEO} className="services-icon-lg" />
                </Card.Body>
                <div className="bottom-curve"></div>
              </Card>
            </div>
          </Col>
          <Col
            lg={3}
            className="mb-lg-0 mb-2 position-relative d-none d-md-block"
          >
            <div className="service-wrapper">
              <Card className="services shadow border-0">
                <Card.Body>
                  <Card.Title className="wow animate__animated animate__fadeInUp count">
                    02
                  </Card.Title>
                  <Card.Title className="wow animate__animated animate__fadeInUp">
                    Search Engine Marketing (SEM)
                  </Card.Title>
                  {/* <AiOutlineFund className="services-icon-lg" /> */}
                  <img src={SEM} className="services-icon-lg" />
                </Card.Body>
                <div className="bottom-curve"></div>
              </Card>
            </div>
          </Col>
          <Col
            lg={3}
            className="mb-lg-0 mb-2 position-relative d-none d-md-block"
          >
            <div className="service-wrapper">
              <Card className="services shadow border-0">
                <Card.Body>
                  <Card.Title className="wow animate__animated animate__fadeInUp count">
                    03
                  </Card.Title>
                  <Card.Title className="wow animate__animated animate__fadeInUp">
                    Social Media Marketing (SMM)
                  </Card.Title>
                  {/* <AiOutlineInstagram className="services-icon-lg" /> */}
                  <img src={SMM} className="services-icon-lg" />
                </Card.Body>
                <div className="bottom-curve"></div>
              </Card>
            </div>
          </Col>
          <Col
            lg={3}
            className="mb-lg-0 mb-2 position-relative d-none d-md-block"
          >
            <div className="service-wrapper">
              <Card className="services shadow border-0">
                <Card.Body>
                  <Card.Title className="wow animate__animated animate__fadeInUp count">
                    04
                  </Card.Title>
                  <Card.Title className="wow animate__animated animate__fadeInUp">
                    Digital Marketing Services
                  </Card.Title>
                  {/* <AiOutlineFundProjectionScreen className="services-icon-lg" /> */}
                  <img
                    src={DigitalMarketingServices}
                    className="services-icon-lg"
                  />
                </Card.Body>
                <div className="bottom-curve"></div>
              </Card>
            </div>
          </Col>
          <Col
            lg={3}
            className="mb-lg-0 mb-2 position-relative d-none d-md-block"
          >
            <div className="service-wrapper">
              <Card className="services shadow border-0">
                <Card.Body>
                  <Card.Title className="wow animate__animated animate__fadeInUp count">
                    05
                  </Card.Title>
                  <Card.Title className="wow animate__animated animate__fadeInUp">
                    Lead Generation
                  </Card.Title>
                  {/* <AiOutlineDashboard className="services-icon-lg" /> */}
                  <img src={LeadGeneration} className="services-icon-lg" />
                </Card.Body>
                <div className="bottom-curve"></div>
              </Card>
            </div>
          </Col>
          <Col
            lg={3}
            className="mb-lg-0 mb-2 position-relative d-none d-md-block"
          >
            <div className="service-wrapper">
              <Card className="services shadow border-0">
                <Card.Body>
                  <Card.Title className="wow animate__animated animate__fadeInUp count">
                    06
                  </Card.Title>
                  <Card.Title className="wow animate__animated animate__fadeInUp">
                    Content Writing
                  </Card.Title>
                  {/* <AiOutlineCopy className="services-icon-lg" /> */}
                  <img src={ContentWriting} className="services-icon-lg" />
                </Card.Body>
                <div className="bottom-curve"></div>
              </Card>
            </div>
          </Col>
          <Col
            lg={3}
            className="mb-lg-0 mb-2 position-relative d-none d-md-block"
          >
            <div className="service-wrapper">
              <Card className="services shadow border-0">
                <Card.Body>
                  <Card.Title className="wow animate__animated animate__fadeInUp count">
                    07
                  </Card.Title>
                  <Card.Title className="wow animate__animated animate__fadeInUp">
                    Graphic Design
                  </Card.Title>
                  {/* <AiOutlineBgColors className="services-icon-lg" /> */}
                  <img
                    src={GraphicDesign}
                    className="services-icon-lg"
                    alt="Graphic Designing services"
                  />
                </Card.Body>
                <div className="bottom-curve"></div>
              </Card>
            </div>
          </Col>
          <Col
            lg={3}
            className="mb-lg-0 mb-2 position-relative d-none d-md-block"
          >
            <div className="service-wrapper">
              <Card className="services shadow border-0">
                <Card.Body>
                  <Card.Title className="wow animate__animated animate__fadeInUp count">
                    08
                  </Card.Title>
                  <Card.Title className="wow animate__animated animate__fadeInUp">
                    Blogs
                  </Card.Title>
                  {/* <AiOutlineMessage className="services-icon-lg" /> */}
                  <img src={Blogs} className="services-icon-lg" alt="blogs" />
                </Card.Body>
                <div className="bottom-curve"></div>
              </Card>
            </div>
          </Col>

          <Col lg={12} className="d-block d-md-none">
            <MultiCarousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={5000}
              infinite={false}
            >
              <div className="mb-lg-0 mb-2 position-relative">
                <div className="service-wrapper">
                  <Card className="services shadow border-0">
                    <Card.Body>
                      <Card.Title className="wow animate__animated animate__fadeInUp count">
                        01
                      </Card.Title>
                      <Card.Title className="wow animate__animated animate__fadeInUp">
                        Search Engine Optimization (SEO)
                      </Card.Title>
                      {/* <AiOutlineFundProjectionScreen className="services-icon-lg" /> */}
                      <img src={SEO} className="services-icon-lg" />
                    </Card.Body>
                    <div className="bottom-curve"></div>
                  </Card>
                </div>
              </div>

              <div className="mb-lg-0 mb-2 position-relative">
                <div className="service-wrapper">
                  <Card className="services shadow border-0">
                    <Card.Body>
                      <Card.Title className="wow animate__animated animate__fadeInUp count">
                        02
                      </Card.Title>
                      <Card.Title className="wow animate__animated animate__fadeInUp">
                        Search Engine Marketing (SEM)
                      </Card.Title>
                      {/* <AiOutlineFund className="services-icon-lg" /> */}
                      <img src={SEM} className="services-icon-lg" />
                    </Card.Body>
                    <div className="bottom-curve"></div>
                  </Card>
                </div>
              </div>

              <div className="mb-lg-0 mb-2 position-relative">
                <div className="service-wrapper">
                  <Card className="services shadow border-0">
                    <Card.Body>
                      <Card.Title className="wow animate__animated animate__fadeInUp count">
                        03
                      </Card.Title>
                      <Card.Title className="wow animate__animated animate__fadeInUp">
                        Social Media Marketing (SMM)
                      </Card.Title>
                      {/* <AiOutlineInstagram className="services-icon-lg" /> */}
                      <img src={SMM} className="services-icon-lg" />
                    </Card.Body>
                    <div className="bottom-curve"></div>
                  </Card>
                </div>
              </div>

              <div className="mb-lg-0 mb-2 position-relative">
                <div className="service-wrapper">
                  <Card className="services shadow border-0">
                    <Card.Body>
                      <Card.Title className="wow animate__animated animate__fadeInUp count">
                        04
                      </Card.Title>
                      <Card.Title className="wow animate__animated animate__fadeInUp">
                        Digital Marketing Services
                      </Card.Title>
                      {/* <AiOutlineFundProjectionScreen className="services-icon-lg" /> */}
                      <img
                        src={DigitalMarketingServices}
                        className="services-icon-lg"
                      />
                    </Card.Body>
                    <div className="bottom-curve"></div>
                  </Card>
                </div>
              </div>

              <div className="mb-lg-0 mb-2 position-relative">
                <div className="service-wrapper">
                  <Card className="services shadow border-0">
                    <Card.Body>
                      <Card.Title className="wow animate__animated animate__fadeInUp count">
                        05
                      </Card.Title>
                      <Card.Title className="wow animate__animated animate__fadeInUp">
                        Lead Generation
                      </Card.Title>
                      {/* <AiOutlineDashboard className="services-icon-lg" /> */}
                      <img src={LeadGeneration} className="services-icon-lg" />
                    </Card.Body>
                    <div className="bottom-curve"></div>
                  </Card>
                </div>
              </div>

              <div className="mb-lg-0 mb-2 position-relative">
                <div className="service-wrapper">
                  <Card className="services shadow border-0">
                    <Card.Body>
                      <Card.Title className="wow animate__animated animate__fadeInUp count">
                        06
                      </Card.Title>
                      <Card.Title className="wow animate__animated animate__fadeInUp">
                        Content Writing
                      </Card.Title>
                      {/* <AiOutlineCopy className="services-icon-lg" /> */}
                      <img src={ContentWriting} className="services-icon-lg" />
                    </Card.Body>
                    <div className="bottom-curve"></div>
                  </Card>
                </div>
              </div>

              <div className="mb-lg-0 mb-2 position-relative">
                <div className="service-wrapper">
                  <Card className="services shadow border-0">
                    <Card.Body>
                      <Card.Title className="wow animate__animated animate__fadeInUp count">
                        07
                      </Card.Title>
                      <Card.Title className="wow animate__animated animate__fadeInUp">
                        Graphic Design
                      </Card.Title>
                      {/* <AiOutlineBgColors className="services-icon-lg" /> */}
                      <img src={GraphicDesign} className="services-icon-lg" />
                    </Card.Body>
                    <div className="bottom-curve"></div>
                  </Card>
                </div>
              </div>

              <div className="mb-lg-0 mb-2 position-relative">
                <div className="service-wrapper">
                  <Card className="services shadow border-0">
                    <Card.Body>
                      <Card.Title className="wow animate__animated animate__fadeInUp count">
                        08
                      </Card.Title>
                      <Card.Title className="wow animate__animated animate__fadeInUp">
                        Blogs
                      </Card.Title>
                      {/* <AiOutlineMessage className="services-icon-lg" /> */}
                      <img src={Blogs} className="services-icon-lg" />
                    </Card.Body>
                    <div className="bottom-curve"></div>
                  </Card>
                </div>
              </div>
            </MultiCarousel>
          </Col>
          <Col lg={6} className="text-center text-white">
            <h2 className="main-title wow animate__animated animate__fadeInUp">
              Promoting your business online is the most affordable and fitting
              mode of marketing.
            </h2>
            {/* <p className="main-text wow animate__animated animate__fadeInUp">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Services;
